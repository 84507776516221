import clsx from 'clsx';
import { Component } from 'react';

import { PATH } from '../../routes/constants';
import { isUserOnMobile } from '../../utils/device/breakpoints';
import { Icon } from '../Icon/Icon';
import { Iframe } from '../Iframe/Iframe';
import { OverlayContainer } from '../OverlayContainer/OverlayContainer';
import SimpleButtonComponent from '../button/simple_button';

function ChatCollapseButton({ isChatExpanded, onClick }) {
  return (
    <SimpleButtonComponent
      variant='icon-button'
      className='text-white'
      onClick={onClick}
    >
      <Icon
        name={isChatExpanded ? 'minimize_screen' : 'full_screen'}
        sizeMobile={20}
        size={16}
      />
    </SimpleButtonComponent>
  );
}

function ChatHideButton({ onClick }) {
  return (
    <SimpleButtonComponent
      variant='icon-button'
      className='text-white'
      onClick={onClick}
    >
      <Icon
        name='minus'
        sizeMobile={20}
        size={16}
      />
    </SimpleButtonComponent>
  );
}

function ChatTrigger({ isActive, onClick }) {
  return (
    <div
      className={clsx('fixed bottom-4 right-2 md:bottom-8 md:right-4', {
        'z-30': !isActive,
        'z-50': isActive,
      })}
    >
      <SimpleButtonComponent
        variant='icon-button'
        className={clsx(
          'z-30 rounded-full bg-brand-gray100 text-brand-green500',
          'hover:opacity-100 hover:bg-brand-green500 hover:text-brand-gray100',
          {
            'shadow-md shadow-brand-gray250': !isActive,
          }
        )}
        onClick={onClick}
      >
        <Icon
          name='suporte'
          size={32}
          className='p-2'
        />
      </SimpleButtonComponent>
    </div>
  );
}

function ChatIframe() {
  return (
    <div className='h-full w-full rounded-lg md:rounded-md bg-brand-chat'>
      <Iframe
        className='rounded-lg border-0 shadow-lg md:rounded-md'
        calculateResize={false}
        src={PATH.CHAT}
        title='Chat'
      />
    </div>
  );
}

export class SupportChat extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isChatExpanded: false,
      isChatOpened: false,
    };

    this.toggleChatExpanded = this.toggleChatExpanded.bind(this);
    this.toggleChatOpen = this.toggleChatOpen.bind(this);
  }

  toggleChatExpanded() {
    this.setState((state) => ({ ...state, isChatExpanded: !state.isChatExpanded }));
  }

  toggleChatOpen() {
    const isOpen = this.state.isChatOpened;

    this.setState((state) => ({ ...state, isChatOpened: !isOpen }));

    if (isOpen === true) {
      this.setState((state) => ({ ...state, isChatExpanded: false }));
    }
  }

  renderMobileChat() {
    return (
      <OverlayContainer
        className='border-t border-brand-gray200 bg-brand-green500 p-4 md:hidden'
        key='mobile-chat-container'
        // TODO: isFullHeight={false}
        isVisible={this.state.isChatOpened}
      >
        <div className='flex flex-col items-end justify-between gap-4 h-[600px]'>
          <ChatHideButton onClick={this.toggleChatOpen} />
          {this.state.isChatOpened && <ChatIframe />}
        </div>
      </OverlayContainer>
    );
  }

  renderDesktopChat() {
    return (
      <div className={clsx('hidden', { 'md:block': this.state.isChatOpened })}>
        <div
          className={clsx('fixed bottom-0 right-0 z-30 h-[415px] w-[470px] bg-transparent', {
            hidden: this.state.isChatExpanded,
            block: !this.state.isChatExpanded,
          })}
        >
          <div className='absolute bottom-10 right-10 flex h-full w-full flex-col items-end gap-2 bg-brand-green500 px-4 py-6 shadow-lg'>
            <ChatHideButton onClick={this.toggleChatOpen} />

            <ChatCollapseButton
              isChatExpanded={this.state.isChatExpanded}
              onClick={this.toggleChatExpanded}
            />

            <div className='absolute bottom-10 right-14 h-full w-full'>
              {this.state.isChatOpened && <ChatIframe />}
            </div>
          </div>
        </div>

        <OverlayContainer
          className='bg-brand-green500 border-t border-brand-gray200 h-full flex w-full items-center px-4 py-6 flex-col gap-4'
          key='desktop-chat-container'
          // TODO: isFullHeight={false}
          isVisible={this.state.isChatOpened && this.state.isChatExpanded}
        >
          <div className='flex justify-center gap-4 self-end'>
            <ChatHideButton onClick={this.toggleChatOpen} />

            <ChatCollapseButton
              isChatExpanded={this.state.isChatExpanded}
              onClick={this.toggleChatExpanded}
            />
          </div>

          <div className='w-full lg:w-2/3 h-[600px]'>
            <ChatIframe />
          </div>
        </OverlayContainer>
      </div>
    );
  }

  render() {
    const isMobile = isUserOnMobile();

    return (
      <>
        <ChatTrigger
          isActive={this.state.isChatOpened}
          onClick={this.toggleChatOpen}
        />

        {isMobile && this.renderMobileChat()}
        {!isMobile && this.renderDesktopChat()}
      </>
    );
  }
}
