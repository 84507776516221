import React, { Component } from 'react';

import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';
import { LinkButton } from '../button/LinkButton';
import SimpleButtonComponent from '../button/simple_button';
import AccountSelector from './account_selector';
import { MobileMenu } from './menu/mobile/MobileMenu';

function AccountManager({ name = null, email = null, isPrivateBanker = false }) {
  const isVisible = name || email;

  return (
    isVisible && (
      <div className='flex items-center gap-2 pr-2'>
        <span className='font-semibold'>
          {translate(isPrivateBanker ? 'PRIVATE_BANKER' : 'ACCOUNT_MANAGER')}:
        </span>

        <span>{name ?? '-'}</span>

        {email && (
          <LinkButton
            href={`mailto:${email}`}
            isExternal
            variant='icon'
          >
            <Icon
              name='mail'
              size={24}
            />
          </LinkButton>
        )}
      </div>
    )
  );
}

function ClientSupport() {
  return (
    <div className='flex items-center gap-2 pl-2'>
      <span className='font-semibold'>{translate('CLIENT_SUPPORT')}:</span>

      <span className='flex gap-2'>
        <SimpleButtonComponent
          variant='icon-button'
          onClick={null}
        >
          <Icon
            name='telefone'
            size={24}
          />
        </SimpleButtonComponent>

        <SimpleButtonComponent
          variant='icon-button'
          onClick={null}
        >
          <Icon
            name='suporte'
            size={24}
          />
        </SimpleButtonComponent>
      </span>
    </div>
  );
}

export class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = { isMenuOpen: false };

    this.toggleMenuOpen = this.toggleMenuOpen.bind(this);
  }

  toggleMenuOpen() {
    this.setState((state) => ({ ...state, isMenuOpen: !state.isMenuOpen }));
  }

  render() {
    const accountManager = {
      name: this.props.userInfo['ComercialNome'] ?? null,
      email: this.props.userInfo['ComercialEmail'] ?? null,
    };

    return (
      <div
        id='nav-selector'
        className='flex h-14 items-center gap-x-4 bg-brand-gray300 px-3 text-zinc-600 lg:justify-between lg:px-6'
      >
        <div className='lg:hidden'>
          <SimpleButtonComponent
            variant='icon-button'
            onClick={this.toggleMenuOpen}
          >
            <Icon
              name='hamburguer'
              size={32}
            />
          </SimpleButtonComponent>
        </div>

        <AccountSelector
          selectedAccount={this.props.selectedAccount}
          onSelectAccount={this.props.onSelectAccount}
          accounts={this.props.userAccounts}
        />

        <div className='hidden divide-x divide-brand-gray400 lg:inline-flex'>
          <AccountManager
            name={accountManager.name}
            email={accountManager.email}
            isPrivateBanker={this.props.userInfo.isPrivateBanker}
          />

          {/* <ClientSupport /> */}
        </div>

        <MobileMenu
          isOpen={this.state.isMenuOpen}
          pages={this.props.menuPages}
          toggleMenuOpen={this.toggleMenuOpen}
        />
      </div>
    );
  }
}
