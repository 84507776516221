import React, { useEffect, useState } from 'react';
import { isUserOnMobile } from '../../utils/device/breakpoints';
import { translate } from '../../utils/translates/translates_helper';

export function ScrollToTopButton() {
    const [isVisible, setVisible] = useState(false)

    const handleScroll = () => {
        var bodyHeight = document.getElementById("content-body").offsetHeight;
        var showButton = isUserOnMobile() === true ? true : (bodyHeight > 1500);

        if ((document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) && showButton){
            setVisible(true);
        } else {
            setVisible(false);
        }
    };
   
    useEffect(() => {
        window.addEventListener("scroll", handleScroll); 
        return () => window.removeEventListener("scroll", handleScroll);
    });

    return (
        isVisible && (<div className="flex place-content-center fixed bottom-3 md:bottom-7 left-0 right-0 z-30">
            <button 
                onClick={goToTop}
                type="button" 
                className="bg-brand-green500 text-brand-gray100 
                lg:bg-brand-gray100 lg:text-brand-green500 
                lg:active:bg-brand-gray100 lg:active:text-brand-green500 
                lg:focus:bg-brand-gray100 lg:focus:text-brand-green500 
                hover:bg-brand-green500 hover:text-brand-gray100 
                shadow-md shadow-brand-gray250 
                font-medium rounded-full text-sm px-5 py-2.5 text-center me-2 mb-2 z-30">
                {translate("SCROLL_TO_TOP")}
            </button>
        </div>)
    );
}

function goToTop() {
    window.scrollTo({top: 0, behavior: 'smooth'});
}