import clsx from 'clsx';
import { Component } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { PATH } from '../../../../routes/constants';
import { getPagePath } from '../../../../utils/menu/menuPages';
import { Icon } from '../../../Icon/Icon';
import './DesktopMenu.css';

function MenuItemPageLink({ onClick = null, pagePath, pageTitle }) {
  return (
    <Link
      className='page-title'
      onClick={onClick}
      to={PATH.BASE + pagePath}
    >
      {pageTitle}
    </Link>
  );
}

function MenuItemParentPage({ children, isOpen = false, onClick, pageTitle }) {
  return (
    <div className={clsx('details', { open: isOpen })}>
      <div
        className='page-title'
        onClick={onClick}
      >
        <span>{pageTitle}</span>
        <Icon name='seta' size={18} className={clsx('flex', { 'rotate-180': isOpen })} />
      </div>

      <div className={clsx({ hidden: !isOpen, block: isOpen })}>{children}</div>
    </div>
  );
}

function MenuPageList({
  current,
  level = 0,
  levelPages,
  navigation,
  onClickParentPage,
  userPages,
}) {
  return (
    <ul className={`level-${level}`}>
      {levelPages.map((page) => {
        const isActive = !!current && current[level] === page.id;
        const isOpen = !!navigation && navigation.includes(page.id);
        const subPages = userPages.filter((p) => p.parentId === page.id);

        return (
          <li
            key={page.id}
            className={clsx({ active: isActive })}
          >
            {page.path ? (
              <MenuItemPageLink
                pagePath={page.path}
                pageTitle={page.title}
              />
            ) : (
              <MenuItemParentPage
                isOpen={isOpen}
                onClick={(event) => onClickParentPage(event, page.id)}
                pageTitle={page.title}
              >
                <MenuPageList
                  current={current}
                  level={level + 1}
                  levelPages={subPages}
                  navigation={navigation}
                  onClickParentPage={onClickParentPage}
                  userPages={userPages}
                />
              </MenuItemParentPage>
            )}
          </li>
        );
      })}
    </ul>
  );
}

class DesktopMenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = { navigation: props.current ?? [] };

    this.handleClickParentPage = this.handleClickParentPage.bind(this);
  }

  setNavigation(navigation) {
    this.setState((state) => ({ ...state, navigation }));
  }

  handleClickParentPage(event, pageId) {
    event.preventDefault();

    const { navigation } = this.state;
    let newNavigation = [];

    const pageIndex = navigation.findIndex((page) => page === pageId);

    if (pageIndex < 0) {
      newNavigation = [...navigation, pageId];
    } else {
      newNavigation = [...navigation.slice(0, pageIndex), ...navigation.slice(pageIndex + 1)];
    }

    this.setNavigation(newNavigation);
  }

  render() {
    const rootPages = this.props.pages.filter((page) => !page.parentId);
    
    return (
      <nav
        id='menu'
        className='hidden lg:inline-flex'
      >
        <div className='menu-container'>
          <div className='list-container'>
            <MenuPageList
              current={this.props.current}
              levelPages={rootPages}
              navigation={this.state.navigation}
              onClickParentPage={this.handleClickParentPage}
              userPages={this.props.pages}
            />
          </div>
        </div>
      </nav>
    );
  }
}

export function DesktopMenu(props) {
  const location = useLocation();
  const { pages = [] } = props;

  const pathname = location?.pathname?.toLowerCase();
  const search = location?.search?.toLowerCase();

  let currentPage = pages.find((p) =>
    (PATH.BASE + p.path).toLowerCase().startsWith(pathname + search)
  );

  if (!currentPage) {
    currentPage = pages.find((p) => (PATH.BASE + p.path).toLowerCase() === pathname);
  }

  const currentNavigation = getPagePath(pages, currentPage?.id);

  return (
    <DesktopMenuComponent
      current={currentNavigation}
      {...props}
    />
  );
}
