export const MASK_TYPE = {
  DATE: 'date',
  DATE_TIME: 'datetime',
  DATE_TIME_LONG: 'datetime_long',
  TIME: 'time',
  DECIMALS: 'decimals',
  NEGATIVE_DECIMALS: 'negative_decimals',
  CURRENCY: 'currency',
  PERCENTAGE: 'percentage',
};

export const DATE_MASKS = [MASK_TYPE.DATE, MASK_TYPE.TIME, MASK_TYPE.DATE_TIME];

export const FORMAT_TYPE = {
  [MASK_TYPE.DATE]: 'yyyy-MM-dd',
  [MASK_TYPE.DATE_TIME]: 'yyyy-MM-dd HH:mm',
  [MASK_TYPE.DATE_TIME_LONG]: 'yyyy-MM-dd HH:mm:ss',
  [MASK_TYPE.TIME]: 'HH:mm',
  [MASK_TYPE.DECIMALS]: 'fixedPoint',
  [MASK_TYPE.NEGATIVE_DECIMALS]: 'fixedPoint',
  [MASK_TYPE.CURRENCY]: 'currency',
  [MASK_TYPE.PERCENTAGE]: 'percent',
};

export function getColumnFormat({ mask = null, maskType = null }) {
  let format = null;

  if (maskType || mask) {
    let formatType = mask?.length ? mask : FORMAT_TYPE[maskType];
    let formatMask = undefined;

    if (/^{.*}$/.test(mask)) {
      formatMask = JSON.parse(mask) ?? undefined;
    }

    switch (maskType) {
      case MASK_TYPE.DATE:
      case MASK_TYPE.DATE_TIME:
      case MASK_TYPE.TIME:
        format = formatMask ?? { type: formatType };
        break;

      case MASK_TYPE.DECIMALS:
      case MASK_TYPE.NEGATIVE_DECIMALS:
      case MASK_TYPE.CURRENCY:
        format = { type: formatType, ...(formatMask ?? { precision: 2 }) };
        break;

      default:
        format = formatMask ?? { type: mask };
        break;
    }
  }

  return format;
}
