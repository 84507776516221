import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

export function LinkButton({
  children,
  variant = 'default',
  isExternal = false,
  direction = 'col',
  ...props
}) {
  const className = clsx('cursor-pointer hover:opacity-75 disabled:cursor-not-allowed', {
    'inline-flex min-w-[unset] p-0': variant === 'icon',
    'pb-1 border-b-2 border-brand-green300 w-fit': variant === 'default',
    'py-2 underline w-fit': variant === 'underline',
  });

  return isExternal ? (
    <a
      target='_blank'
      rel='noreferrer'
      className={className}
      {...props}
    >
      {children}
    </a>
  ) : (
    <Link
      className={className}
      {...props}
    >
      <span
        className={clsx('text-brand-green500 font-medium', {
          'flex items-center': variant === 'icon',
          'flex-col': variant === 'icon' && direction === 'col',
          'flex-row gap-1': variant === 'icon' && direction === 'row',
        })}
      >
        {children}
      </span>
    </Link>
  );
}
