import { LoadPanel } from 'devextreme-react/load-panel';
import React from 'react';

import { getLabel } from '../../utils/translates/login_labels';

function LoadingComponent({ isVisible = true, position = '', customMessage = undefined }) {
    return (
        <LoadPanel
            message={customMessage ?? getLabel("LOADING")}
            shadingColor="rgba(0,0,0,0.4)"
            position={{ of: position }}
            visible={isVisible}
            showIndicator={true}
            shading={true}
            showPane={true}
            hideOnOutsideClick={false}
        />
    );
}

export default LoadingComponent;
