import React from 'react';
import SimpleButtonComponent from '../../components/button/simple_button';
import DialogComponent from '../../components/dialog/dialog';
import { Icon } from '../../components/Icon/Icon';
import LoadingComponent from '../../components/loading/load_panel';
import PATH from '../../routes/constants';
import { logoutUser } from '../../utils/integration/logout';
import { localStorageHelper } from '../../utils/storage/local_storage';
import { getLabel } from '../../utils/translates/login_labels';
import LoginModal from './login_modal';

export default class RetryAuthDialog extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDialog: false,
            isError: false,
            isLoading: false,
            integrationCallback: null,
            dialogData: {
                content: this.renderDialogContent(),
            },
            dialogButtons: [
                {
                    onClick: this.handleCancelLoginProcess,
                    label: getLabel('LOGOUT')
                }
            ]
        };
        window.retryAuthDialog = this;
    }

    renderConfirmationPopup = () => {
        return(
            <div id="modelConfirm" className="fixed z-50 inset-0 bg-gray-900 bg-opacity-60 overflow-y-auto h-full w-full px-4 ">
                <div className="relative top-40 mx-auto shadow-xl rounded-md bg-white max-w-md">
                    <div className="p-6 pt-0 text-center">
                        <Icon name="erro" height={80} width={80}/>
                        <h3 className="text-xl font-normal text-gray-500 mt-5 mb-6"> {getLabel("CANCEL_CONFIRMATION_1")} </h3>
                        <div className='flex w-full justify-between'>
                            <SimpleButtonComponent onClick={() => this.doAuthentication(false)}>
                                <span>{getLabel("LOGOUT")}</span>
                            </SimpleButtonComponent>
                            <SimpleButtonComponent onClick={() => this.handleAuthenticationDialog(this.state.integrationCallback)}>
                                <span>{getLabel("BACK")}</span>
                            </SimpleButtonComponent>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderDialogContent = (showConfirmationPopup = false) => {
        const loginType = localStorageHelper.getAccessType();
        const userName = localStorageHelper.getLoginUserName();
        
        return (
            <div className='flex justify-center w-full'>
                {showConfirmationPopup && this.renderConfirmationPopup()}
                <LoginModal 
                    loginByAuthDialog={true} 
                    handleAuthentication={this.doAuthentication} 
                    loginType={loginType} 
                    userName={userName}/>
            </div>
        );
    }

    handleAuthenticationDialog = (callback) => {
        this.setState({
            showDialog: true,
            integrationCallback: callback,
            dialogData: {
                content: this.renderDialogContent(false),
            }
        });
    }

    handleCancelLoginProcess = () => {
        this.setState({
            showDialog: true,
            dialogData: {
                content: this.renderDialogContent(true)
            }
        });
    }

    doAuthentication = (isSuccess) => {
        if(!isSuccess){
            logoutUser(PATH.LOGIN_UNAUTHORIZED);
        } else {
            this.closeDialog();
            this.state.integrationCallback(isSuccess);
        }
    }

    closeDialog = () => {
        this.setState({
            showDialog: false,
            dialogData: {
                content: ""
            }
        });
    }

    render() {
        return (
            <>
                <LoadingComponent isVisible={this.state.isLoading} />

                <DialogComponent
                    visible={this.state.showDialog}
                    callback={this.closeDialog}
                    dialog={this.state.dialogData}
                    hideOnOutsideClick={false}
                    isPrintable={false}
                    showCloseButton={false}
                    buttons={this.state.dialogButtons}
                />
            </>
        );
    }
}
