import { Component, createRef } from 'react';

import { PATH } from '../../routes/constants';
import { getEntityForms } from '../../server/account';
import { showError } from '../../utils/notification/showFeedback';
import { LOCAL_STORAGE } from '../../utils/storage/constants';
import { localStorageHelper } from '../../utils/storage/local_storage';
import { translate, translateWithData } from '../../utils/translates/translates_helper';
import { JsxFromString } from '../JsxFromString/JsxFromString';
import { ConfirmActionDialog } from '../dialog/ConfirmActionDialog';
import './UserFormsVerificationInfoDialog.css';

export class UserFormsVerificationInfoDialog extends Component {
  constructor(props) {
    super(props);

    this.dialogRef = createRef(null);

    this.state = {
      message: undefined,
    };

    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  handleClickOnDialog() {
    if (this.dialogRef.current) {
      const links = this.dialogRef.current.getElementsByTagName('a');

      Array.from(links).forEach((link) => link.addEventListener('click', this.handleConfirm));
    }
  }

  loadData() {
    const isVisible = localStorageHelper.getValue(LOCAL_STORAGE.USER_VERIFY_FORMS_VISIBLE);

    if (isVisible === 'false') {
      this.setState((state) => ({ ...state, message: undefined }));
    } else {
      getEntityForms().then(({ errorMessage, data }) => {
        if (errorMessage) {
          showError(errorMessage);
          return;
        }

        const isDmifMessage = !!data[0]?.length || !!data[2]?.length;
        const isGbProMessage = !!data[1]?.length;

        if (isDmifMessage || isGbProMessage) {
          let message = '';

          if (isDmifMessage) {
            const personalDataPage = this.props.menuPages.find((p) => p.codigoPagina === 200046);

            message = message.concat(
              translateWithData('USER_FORMS_DIALOG_DMIF_MESSAGE', [
                PATH.BASE + personalDataPage.path,
                personalDataPage.title,
              ])
            );
          }

          if (isGbProMessage) {
            const goBullingPage = this.props.menuPages.find((p) => p.codigoPagina === 200030);

            message = message.concat(
              translateWithData('USER_FORMS_DIALOG_GBPRO_MESSAGE', [
                PATH.BASE + goBullingPage.path,
                goBullingPage.title,
              ])
            );
          }

          message = message.concat(translate('USER_FORMS_DIALOG_FINAL_MESSAGE'));

          this.setState(
            (state) => ({ ...state, message: message }),
            () => {
              localStorageHelper.setValue(LOCAL_STORAGE.USER_VERIFY_FORMS_VISIBLE, false);
              this.handleClickOnDialog();
            }
          );
        }
      });
    }
  }

  handleConfirm() {
    this.setState((state) => ({ ...state, message: undefined }));
  }

  render() {
    return (
      <ConfirmActionDialog
        confirmLabel='OK'
        onConfirm={this.handleConfirm}
        onCancel={this.handleConfirm}
        showCancelButton={false}
        isVisible={Boolean(this.state.message)}
        hideOnOutsideClick={false}
        width={600}
        id='user-forms-verification-dialog'
      >
        <div ref={this.dialogRef}>
          <JsxFromString domString={this.state.message} />
        </div>
      </ConfirmActionDialog>
    );
  }
}
