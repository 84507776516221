import PATH from '../../routes/constants';
import { getJsonFromString } from '../format/text';

export const getVisiblePages = (pages) => {
  return pages.filter((page) => {
    let isVisible = true;

    if (page.customParams) {
      const params = getJsonFromString(page.customParams);

      if (params && params.hasOwnProperty('isVisible')) {
        isVisible = Boolean(params.isVisible);
      }
    }

    return isVisible;
  });
};

export const getPagePath = (pages, pageId) => {
  if (pageId) {
    const page = pages.find((p) => p.id === pageId);

    if (page?.parentId) {
      return [...getPagePath(pages, page.parentId), page.id];
    }

    return [page.id];
  }

  return null;
};

export const getHomePage = (pages) => {
  const visiblePages = getVisiblePages(pages);
  const visibleLateralPages = visiblePages.filter(x => Number(x?.menuLateral) === 1);
  let defaultPage = visibleLateralPages.find(x => Number(x?.defaultPage) === 1);

  if(!defaultPage){
    defaultPage = visibleLateralPages[0];
  }
  
  if(defaultPage)
    return PATH.BASE + defaultPage?.path;
  else
    return PATH.PAGE_MAIN;
};