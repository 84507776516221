import { HomebankingExclusiveRules, HomebankingRules } from '../../components/NewPassword/NewPassword';
import resourcesConfig from '../../config/api_resources.json';
import { isAuthenticated } from '../../utils/authentication/authentication';
import { getRequestUrl } from '../../utils/format/api';
import {
    encryptData,
    get,
    getWithoutAuth,
    importPublicKey,
    post,
    postWithoutAuth,
} from '../../utils/integration/integration';
import { getLabel, getLoginLanguage, getUserLanguage } from '../../utils/translates/login_labels';

const formatAuthorizationSetting = (setting) => {
    const adesaoId = setting['AutenticacaoAdesaoID'];
    const isActive = adesaoId !== null && Number(setting['AutenticacaoActiva']) === 1;

    return {
        id: setting['FactorAutenticacaoID'],
        name: setting['FactorAutenticacaoMnemonica'],
        isEnabledLogin: Number(setting['FactorAutenticacaoFlagLogin']) === 1,
        isCheckedLogin: isActive && Number(setting['AutenticacaoFlagLogin']) === 1,
        isEnabledOperations: Number(setting['FactorAutenticacaoFlagOperacaoes']) === 1,
        isCheckedOperations: isActive && Number(setting['AutenticacaoFlagOperacoes']) === 1,
        phoneNumber: setting['AutenticacaoTelemovelDigitos'] ?? null,
    };
};

const formatAuthorizationSettingNotifications = (setting) => {

    return {
        id: 'NoticationLogin',
        name: 'NoticationLogin',
        isCheckedSMSLogin: Number(setting['FlagSMS']) === 1,
        isCheckedEmailLogin: Number(setting['FlagEmail']) === 1,
        labelFirst: 'SMS',
        labelSecond: 'EMAIL'
    };
};

async function getAuthorizationSettings() {
    let errorMessage = '';
    let data = [];

    await get(resourcesConfig.SECURITY.GET_AUTHORIZATION_SETTINGS)
        .then(({ errorMessage, result }) => {
            if (errorMessage) {
                errorMessage = getLabel('GENERIC_ERROR');
            } else {
                data = (result?.results[0] ?? []).map(formatAuthorizationSetting);
                data.push((result?.results[1] ?? []).map(formatAuthorizationSettingNotifications)[0]);
            }
        })
        .catch(() => {
            errorMessage = getLabel('GENERIC_ERROR');
        });

    return { errorMessage, data };
}

export async function getPasswordPolicyList() {
    const cachedLanguage = getUserLanguage();
    let errorMessage = '';
    let data = [];

    const languageId = cachedLanguage === 'US' ? 2 : 1;

    const getFunc = isAuthenticated() ? get : getWithoutAuth;

    await getFunc(resourcesConfig.SECURITY.GET_PASSWORD_POLICY_LIST, { LanguageId: languageId })
        .then(({ errorMessage, result }) => {
            if (errorMessage) {
                errorMessage = getLabel('GENERIC_ERROR');
            } else {
                data = result?.results[0] ?? [];
            }
        })
        .catch(() => {
            errorMessage = getLabel('GENERIC_ERROR');
        });

    return { errorMessage, data };
}

export async function getSecurity() {
    let errorMessage = '';
    let data = {
        securitySettings: [],
        passwordRules: [],
    };

    await Promise.all([getAuthorizationSettings(), getPasswordPolicyList()]).then(
        ([authorizationSettings, passwordPolicy]) => {
            data = {
                securitySettings: authorizationSettings.data,
                passwordRules: passwordPolicy.data,
            };

            if (authorizationSettings.errorMessage) {
                errorMessage = authorizationSettings.errorMessage;
            } else if (passwordPolicy.errorMessage) {
                errorMessage = passwordPolicy.errorMessage;
            }
        }
    );

    return { data, errorMessage };
}

const getXmlUpdateSettings = (settings) => {
    const doc = document.implementation.createDocument('', '', null);
    let root = doc.createElement('Root');

    settings.forEach((setting) => {
        if (setting.isCheckedLogin || setting.isCheckedOperations) {
            let settingNode = doc.createElement('FactorAutenticacao');
            settingNode.setAttribute('ID', setting.id);

            if (setting.isCheckedLogin) {
                settingNode.setAttribute('Login', 1);
            }

            if (setting.isCheckedOperations) {
                settingNode.setAttribute('Operacoes', 1);
            }

            root.append(settingNode);
        }
        if (setting.isCheckedSMSLogin || setting.isCheckedEmailLogin) {
            let settingNode = doc.createElement('FactorAutenticacao');
            settingNode.setAttribute('ID', -1);

            if (setting.isCheckedSMSLogin) {
                settingNode.setAttribute('SMS', 1);
            }

            if (setting.isCheckedEmailLogin) {
                settingNode.setAttribute('EMAIL', 1);
            }

            root.append(settingNode);
        }
    });

    return root.innerHTML.toString();
};

const updateSettingSucessfull = (data) => {
    SucessHandleSubmitSettings();
    FinalyHandleSubmitSettings();
    return data;
};

const updateSettingCancelSecondFactor = () => {

    FinalyHandleSubmitSettings();
};

var FinalyHandleSubmitSettings;
var SucessHandleSubmitSettings;

export async function updateSetting(settings, { password }, _finalyHandleSubmitSettings, _sucessHandleSubmitSettings) {
    let error = '';
    let invalidData = [];
    let recordId = undefined;
    FinalyHandleSubmitSettings = _finalyHandleSubmitSettings;
    SucessHandleSubmitSettings = _sucessHandleSubmitSettings;
    const postData = getXmlUpdateSettings(settings).toString();

    const publicKeyUrl = getRequestUrl(resourcesConfig.AUTHENTICATION.PUBLIC_KEY);
    const response = await fetch(publicKeyUrl).then((res) => res.text());
    const publicKey = await importPublicKey(response);
    const encryptedpw = await encryptData(password, publicKey);

    await post(resourcesConfig.SECURITY.SET_SECURITY_SETTINGS, {
        Password: encryptedpw,
        PublicKey: response,
        Type: 'DEFAUT',
        XMLParameters: postData,
    }, false, updateSettingSucessfull, updateSettingCancelSecondFactor)
        .then(({ errorMessage, result }) => {
            if (errorMessage) {
                error = errorMessage.startsWith('[002]') ? getLabel('INVALID_PASSWORD') : getLabel('GENERIC_ERROR');
            } else {
                recordId= updateSettingSucessfull(result?.results[0][0] ?? null);
               
            }
        })
        .catch(() => {
            error = getLabel('GENERIC_ERROR');
        });

    return { error, invalidData, recordId };
}

var FinalyupdatePassword;
var SucessupdatePassword;
var ErrorupdatePassword;


const updatePasswordSucessfull = (data) => {
    SucessupdatePassword();
    FinalyupdatePassword();
    return data;
}

const updatePasswordCancelSecondFactor = () => {
    FinalyupdatePassword();
}

export async function updatePassword({ password, newPassword}, _finalyupdatePassword, _sucessupdatePassword, _errorupdatePassword ) {
    let error = '';
    let invalidData = [];
    let recordId = [];
    FinalyupdatePassword = _finalyupdatePassword;
    SucessupdatePassword = _sucessupdatePassword;
    ErrorupdatePassword = _errorupdatePassword

    const { isAllowed, data } = await isPasswordAllowed(newPassword);

    if (isAllowed) {
        const publicKeyUrl = getRequestUrl(resourcesConfig.AUTHENTICATION.PUBLIC_KEY);
        const response = await fetch(publicKeyUrl).then((res) => res.text());
        const publicKey = await importPublicKey(response);
        const encryptedpw = await encryptData(password, publicKey);
        const encryptedpwNew = await encryptData(newPassword, publicKey);

        await post(resourcesConfig.SECURITY.SET_NEW_PASSWORD, {
            Password: encryptedpw,
            PublicKey: response,
            NewPassword: encryptedpwNew,
            Type: 'DEFPWD',
        }, false, updatePasswordSucessfull,updatePasswordCancelSecondFactor)
            .then(({ errorMessage, result }) => {
                if (errorMessage) {
                    error = errorMessage.startsWith('[002]') ? getLabel('INVALID_PASSWORD') : getLabel('GENERIC_ERROR');
                } else {
                    recordId = updatePasswordSucessfull(result?.results[0][0] ?? null);
                }
            })
            .catch(() => {
                error = getLabel('GENERIC_ERROR');
            });
    } else {
        invalidData = data;
    }

    return { error, invalidData, recordId };
}

export async function isPasswordAllowed(password) {
    let isAllowed = false;
    let data = [];

    const publicKeyUrl = getRequestUrl(resourcesConfig.AUTHENTICATION.PUBLIC_KEY);
    const response = await fetch(publicKeyUrl).then((res) => res.text());
    const publicKey = await importPublicKey(response);
    const encryptedpw = await encryptData(password, publicKey);

    await postWithoutAuth(resourcesConfig.SECURITY.GET_IS_PASSWORD_VALID_BY_POLICY, {
        Password: '',
        PublicKey: response,
        NewPassword: encryptedpw,
        Type: '',
    }).then(({ errorMessage, result }) => {
        if (!errorMessage) {
            var policiesViolated = (result?.results[0] ?? []).map((item) => item['PolicyRule']);
            policiesViolated = HomebankingRulesViolation(password).length > 0 ? policiesViolated.concat(HomebankingRulesViolation(password)) : policiesViolated;
            data = policiesViolated;
            isAllowed = data.length === 0;
        } else {
            data = HomebankingRulesViolation(password);
            isAllowed = data.length === 0;
        }
    });

    return { data, isAllowed };
}

export function HomebankingRulesViolation(password) {
    var homebankingViolations = []
    if (password.length > 0 && HomebankingExclusiveRules.length > 0) {
        //Caracteres proibidos
        if (HomebankingRules.FORBIDDEN_CHARS.some(char => password.includes(char))) {
            homebankingViolations.push(HomebankingExclusiveRules.find(x => x?.Mnemonica === "FORBIDDEN_CHARS")?.Descricao);
        }
    }
    return homebankingViolations;
}