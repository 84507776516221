import { removeHtmlSpaces } from '../text';
import { getChildNodesFromXmlString } from './domParse';
import {
  formatStringIntoHtmlTemplate,
  getHtmlWithDataAsContent,
  getHtmlWithDataValueAsAttribute,
  getHtmlWithTranslateMessages,
} from './htmlTemplate';

export function isReactTemplate(string) {
  return /^`.*`$/.test(removeHtmlSpaces(string));
}

export function getChildNodesFromReactString(str, data) {
  let html = formatStringIntoHtmlTemplate(str);
  html = getHtmlWithDataValueAsAttribute(html, data);
  html = getHtmlWithDataAsContent(html, data);
  html = getHtmlWithTranslateMessages(html);
  html = getChildNodesFromXmlString(html);

  return html;
}
