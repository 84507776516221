import { localStorageHelper } from "../storage/local_storage";

String.prototype.toCurrency = function (decimals, currency, emptyIfZero = false, emptyIfNaN = false, negativesClass, leftSymbol, truncRound = false, minDecimals) {
    if (arguments.length === 1 && typeof arguments[0] === 'object' && arguments[0]!==null) {
        var o = arguments[0];
        if (typeof o.decimals !== 'undefined') decimals = o.decimals;
        if (typeof o.currency !== 'undefined') currency = o.currency;
        if (typeof o.emptyIfZero !== 'undefined') emptyIfZero = o.emptyIfZero;
        if (typeof o.emptyIfNaN !== 'undefined') emptyIfNaN = o.emptyIfNaN;
        if (typeof o.negativesClass !== 'undefined') negativesClass = o.negativesClass;
        if (typeof o.leftSymbol !== 'undefined') leftSymbol = o.leftSymbol;
        if (typeof o.truncRound !== 'undefined') truncRound = o.truncRound;
    }

    var n = ('' + this.toValue(true));
    if (isNaN(n)) n = '';
    if (n === '')
        if (emptyIfNaN) return ''; else n = '0';

    var n1 = '0', n2 = '', minDecs = null;
    var dsymb = ',', gsymb = '.', jsymb = ('' + 0.5)[1];
    if (typeof decimals === 'undefined' || decimals === null || (isNaN(decimals) && decimals !== 'auto')) decimals = 2;
    if (typeof currency === 'undefined' || currency === null) currency = '';
    if (n.indexOf('e') > 0) n = parseFloat(n).toFixed(12);
    if (n.length) {
        var ng = (parseFloat(n) < 0 ? '-' : '');
        var pi = 0, pd = '0';
        var p = n.indexOf(jsymb);
        if (p > 0) {
            pi = Math.abs(parseInt(n.slice(0, p)))
            pd = n.slice(p + 1);
        }
        else
            pi = Math.abs(parseInt(n));
        if (decimals === 'auto' || decimals < 0) {
            minDecs = Math.abs(decimals === 'auto' ? 0 : decimals);
            if (minDecs > 10) minDecs = 10;
            decimals = 10;
        }
        if (decimals > 0) {
            if (truncRound)
                pd = pd.slice(0, decimals);
            else {
                var pd2 = parseFloat('0' + jsymb + pd) * Math.pow(10, decimals);
                pd2 = Math.round(pd2) / Math.pow(10, decimals);
                if (pd2 >= 1) { pi++; pd2 -= 1; };
                pd2 = pd2.toFixed(decimals);
                p = pd2.indexOf(jsymb);
                pd = pd2.slice(p + 1);
            }
        } else {
            pd = '0';
            if (!truncRound)
                pi = parseInt(Math.abs(parseFloat(n).toFixed(0)));
        }
        n1 = pi.toFixed(0);
        if (n1.length > 3) {
            if ((n1.length % 3) > 0) n1 = '#'.repeat(3 - (n1.length % 3)) + n1;
            n1 = n1.match(/(.{1,3})/g).join(gsymb);
            n1 = n1.slice(n1.lastIndexOf('#') + 1);
        }
        n1 = ng + n1;
        if (decimals > 0)
            n2 = (pd + '0'.repeat(decimals)).slice(0, decimals);
    }

    var vl = parseFloat(n1 + (n2 !== '' ? jsymb + n2 : ''));
    if (vl === -0) { // javascript at its's best...
        vl = 0;
        if (n1[0] === '-') n1 = n1.slice(1);
    }
    if (emptyIfZero && vl === 0) return '';

    minDecimals = minDecimals ?? minDecs;
    if (minDecimals !== null) {
        if (n2.length)
            n2 = n2.split('0').join(' ').trimEnd().split(' ').join('0');
        if (n2.length < minDecimals)
            n2 += '0'.repeat(minDecimals - n2.length);
    }

    var r = n1 + (n2 !== '' ? dsymb + n2 : '');
    if (currency !== '') {
        if (typeof leftSymbol === 'undefined' || leftSymbol === null) leftSymbol = false;
        if (leftSymbol) r = currency + ' ' + r; else r += (' ' + currency);
    }
    
    if (typeof negativesClass === 'undefined' || negativesClass === null) negativesClass = '';
    if (negativesClass !== '' && vl < 0)
        return <span className={negativesClass}> {r} </span>;
    else
        return r;
};

String.prototype.toValue = function (allowNullReturn) {
    var n = this.toString().toLowerCase().trim();
    if (n.indexOf('e-') > 0 || n.indexOf('e+') > 0) n = parseFloat(n).toFixed(12);
    for (var i = 0; i < n.length; i++) {
        if ('-,.0123456789'.indexOf(n[i]) < 0) {
            n = n.slice(0, i) + n.slice(i + 1); i--;
        }
    }
    var dsymb = ',', gsymb = '.', jsymb = ('' + 0.5)[1];
    if (n.indexOf(gsymb) < n.lastIndexOf(gsymb))
        while (n.indexOf(gsymb) + 1) n = n.replace(gsymb, '');
    if (n.indexOf(dsymb) < n.lastIndexOf(dsymb))
        while (n.indexOf(dsymb) + 1) n = n.replace(dsymb, '');
    var p1 = n.indexOf(gsymb), p2 = n.indexOf(dsymb);
    if (p1 > -1 && p2 > p1) {
        while (n.indexOf(gsymb) + 1) n = n.replace(gsymb, '');
        if (dsymb !== jsymb) { while (n.indexOf(dsymb) + 1) n = n.replace(dsymb, jsymb); };
    } else if (p2 > -1 && p2 < p1) {
        while (n.indexOf(dsymb) + 1) n = n.replace(dsymb, '');
        if (gsymb !== jsymb) { while (n.indexOf(gsymb) + 1) n = n.replace(gsymb, jsymb); };
    } else if (p1 > -1 && gsymb !== jsymb) {
        while (n.indexOf(gsymb) + 1) n = n.replace(gsymb, jsymb);
    } else if (p2 > -1 && dsymb !== jsymb) {
        while (n.indexOf(dsymb) + 1) n = n.replace(dsymb, jsymb);
    }

    if (isNaN(n) || n === '') return ((typeof allowNullReturn !== undefined && allowNullReturn !== null && allowNullReturn) ? null : 0.0);
    var v = parseFloat(n);
    if (v === -0) v = 0;
    return parseFloat(v);
}
Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));

    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export function formatNumberToCurrency({value, decimals, currency, minDecimals, showCurrency = true, emptyIfZero = false, emptyIfNaN = false, negativesClass, leftSymbol = false, truncRound}) {
    if(currency !== null && currency !== undefined){
        let coin = localStorageHelper.getUserCoins().find((c) => c.Mnemonica === currency);
        truncRound = truncRound ?? coin.Truncar;
        decimals = decimals ?? coin.Decimais;
    }

    currency = showCurrency ? currency : null;

    if (value === null || value === undefined){
        if(emptyIfNaN)
            return null;
        else
            return "0,00";
    }
        
    return value.toString().toCurrency(decimals, currency, emptyIfZero, emptyIfNaN, negativesClass, leftSymbol, truncRound, minDecimals);
}