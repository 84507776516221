
import { URL_RESOURCE } from "../../routes/constants";
import * as auth from "../authentication/authentication";
import { localStorageHelper } from "../storage/local_storage";

export function logoutUser(urlToRedirect) {
    urlToRedirect = localStorageHelper.getAccessType() === "1" ? urlToRedirect + URL_RESOURCE.LOGOUT_DMIF : urlToRedirect;
    auth.deleteAuthInfo();
    setTimeout(() => {
        window.location.href = urlToRedirect;
    }, 100);
}
