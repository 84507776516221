import { formatDate, formatNumber } from 'devextreme/localization';

import { FORMAT_TYPE, MASK_TYPE } from '../../components/datagrid/columnUtils';
import { formatNumberToCurrency } from './currency_format';
import { formatNumberToPercentage } from './percent';

export function getNumberFormatted(value, format) {
  if  (value === null || value === undefined) {
    return null;
  }

  let result = null;

  switch(format?.type) {
    case MASK_TYPE.DECIMALS:
    case FORMAT_TYPE[MASK_TYPE.DECIMALS]:
      result = formatNumberToCurrency({
        value: value,
        truncRound: 0,
        decimals: format?.precision ?? 0,
        currency: null
      });
      break;

    case MASK_TYPE.PERCENTAGE:
    case FORMAT_TYPE[MASK_TYPE.PERCENTAGE]:
      result = formatNumberToPercentage({
        value: value,
        decimalPlaces: format?.precision ?? 0,
      });
      break;

    default:
      result = formatNumber(value, { ...format });
      break;
  }

  return result;  
}

export function getDateFormatted(value, format) {
  return formatDate(new Date(value), { ...format });
}
