import { useRouteError } from 'react-router-dom';
import errorImage from '../assets/img/500.jpg';
import { PageTitle } from '../components/title/page_title';
import { caller } from '../utils/integration/integration';
import { getLabel } from '../utils/translates/login_labels';
import resourcesConfig from "../config/api_resources.json";
import { useEffect } from 'react';

export function ErrorPage() {
  let error = useRouteError();

  useEffect(() => {    
    caller.post(
      resourcesConfig.DEFAULT.LOG_ERROR, 
      {
        Path: window.location.pathname,
        Error: error?.stack ?? error.toString()
      }
    );
  }, []);
  
  return (
    <div className='flex flex-col gap-4 lg:gap-5'>
      <PageTitle.Component title={getLabel('INTERNAL_ERROR_TITLE')} />

      <div className='flex flex-col md:flex-row items-center'>
        <img alt='Not Found' src={errorImage} className='object-contain h-40' />
        <div className='flex flex-col md:mx-8 gap-2 md:gap-4 text-center md:text-start'>
          <span className='text-brand-green500 text-4xl'>500</span>
          <span className='text-xl'>{getLabel('INTERNAL_ERROR_DESC_1')}</span>
          <span className='text-base'>{getLabel('INTERNAL_ERROR_DESC_2')}</span>
        </div>
      </div>
    </div>
  );
}
