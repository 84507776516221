import { translate } from '../../translates/translates_helper';

export function getHtmlWithTranslateMessages(html) {
  let result = html;

  if (html) {
    const translateRegex = new RegExp(/\{translate(.+?)\}/, 'g');
    const translateMatches = html.match(translateRegex);

    if (translateMatches) {
      /*const notKeyRegex = supportsRegexLookAheadLookBehind() 
        ? new RegExp(/(?<=\{translate\(").*(?="\)\})/, 'g') 
        : new RegExp(/(?:\{translate\(").*(?="\)\})/, 'g');*/

      //const notKeyRegex = new RegExp(/(?<=\{translate\(").*(?="\)\})/, 'g') ;

      translateMatches.forEach((substring) => {
        
        //Ajuste para funcionar em Safari, precisa de testes
        const keyMatch = substring.match(substring.substring(substring.indexOf("translate(\"") + 11).replace("\")}",""));
        //const keyMatch = substring.match(notKeyRegex);
        //const keyMatch = substring.match(substring.substring(substring.indexOf("translate(") + 10).replace(")}",""))[0] ?? '';

        const key = keyMatch?.length > 0 ? keyMatch[0] : '';
        const value = translate(key);

        result = result.replaceAll(substring, value);
      });
    }
  }

  return result;
}

export const isDataValid = (data) => Boolean(data && Object.keys(data)?.length);

export function replaceHtmlTemplateWithDataValue(html, data, dataRegex, valueTemplate) {
  let result = html;

  if (html && isDataValid(data) && dataRegex) {
    const dataRegexMatches = result.match(dataRegex);

    if (dataRegexMatches) {
      const dataMatches = dataRegexMatches.reduce(
        (acc, curr) => (acc.includes(curr) ? acc : [...acc, curr]),
        []
      );
      //const notKeyRegex = supportsRegexLookAheadLookBehind() ? new RegExp(/(?<=\{data.).*(?=\})/, 'g') : "";

      dataMatches.forEach((substring) => {
        /*const key = supportsRegexLookAheadLookBehind()
          ? substring.match(notKeyRegex)[0] ?? ''
          : substring.match(substring.substring(substring.indexOf("data.") + 5).replace("}",""))[0] ?? '';*/


        //Alteração para funcionar em safari, precisa de testes
        const key = substring.match(substring.substring(substring.indexOf("data.") + 5).replace("}",""))[0] ?? '';

        if (key) {
          const dataProps = key.split('.');
          const dataValue = dataProps.reduce((acc, curr) => acc[curr], data) ?? '';
          const value = typeof valueTemplate === 'function' ? valueTemplate(dataValue) : dataValue;

          result = result.replace(new RegExp(substring, 'g'), value);
        }
      });
    }
  }

  return result;
}

export function getHtmlWithDataValueAsAttribute(html, data) {
  let result = html;

  if (html && isDataValid(data)) {
    const dataRegex = new RegExp(/=\{data.(.+?)\}/, 'g');

    result = replaceHtmlTemplateWithDataValue(html, data, dataRegex, (v) => `="${v}"`);
  }

  return result;
}

export function getHtmlWithDataAsContent(html, data) {
  let result = html;

  if (html && data) {
    const dataRegex = new RegExp(/\{data.(.+?)\}/, 'g');

    result = replaceHtmlTemplateWithDataValue(result, data, dataRegex);
  }

  return result;
}

export function formatStringIntoHtmlTemplate(str) {
  const formattedStr = str.replace(/^`|`$/g, '');

  return `<div>${formattedStr}</div>`;
}
