import { Component } from 'react';
import { Navigate } from 'react-router-dom';

import LoadingComponent from '../components/loading/load_panel';
import { PATH } from '../routes/constants';
import * as auth from '../utils/authentication/authentication';

export class LogoutPage extends Component {
    constructor(props) {
        super(props);

        this.state = { isLoading: true };
    }

    componentDidMount() {
        auth.logout().finally(() => {
            this.setState((state) => ({ ...state, isLoading: false }));

            if (this.props.onLogout) {
                this.props.onLogout();
            }
        });
    }

    render() {
        return this.state.isLoading ? (
            <LoadingComponent />
        ) : (
            <Navigate
                to={PATH.LOGIN}
                replace
            />
        );
    }
}
