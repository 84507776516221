import { getChildNodesFromHtmlString } from './domParse';
import { getChildNodesFromReactString, isReactTemplate } from './reactTemplate';

export function getChildNodesFromString(stringTemplate, isReact = false, data = null) {
  let childNodes = [];

  if (stringTemplate && stringTemplate.length > 0) {
    try {
      if (isReact) {
        const template = isReactTemplate(stringTemplate) ? stringTemplate : '`{' + stringTemplate + '}`';

        childNodes = getChildNodesFromReactString(template, data);
      } else {
        childNodes = getChildNodesFromHtmlString(stringTemplate);
      }
    } catch (e) {
      childNodes = [];
    }
  }

  return childNodes;
}
