import notify from 'devextreme/ui/notify';
import { Fragment } from 'react';
import ReactDOM from 'react-dom/client';

import { getLabel } from '../translates/login_labels';
import { Icon } from '../../components/Icon/Icon';

const FEEBACK_TYPE = {
    ERROR: 'error',
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
};

const FEEDBACK_ICON = {
    [FEEBACK_TYPE.ERROR]: 'erro',
    [FEEBACK_TYPE.INFO]: 'info',
    [FEEBACK_TYPE.SUCCESS]: 'check',
    [FEEBACK_TYPE.WARNING]: 'erro',
};

function FeedbackContent({ message, title, type }) {
    return (
        <div className='flex w-full items-center justify-between gap-4 px-2'>
            <div className='flex items-center gap-4'>
                <Icon
                    name={FEEDBACK_ICON[type]}
                    sizeMobile={28}
                    size={32}
                />

                <div className='leading-normal'>
                    {title && <h6 className='text-base font-semibold'>{title}</h6>}

                    <p className='text-sm font-normal'>
                        {message.map((line, index) => (
                            <Fragment key={index}>
                                <span>{line}</span>
                                <br />
                            </Fragment>
                        ))}
                    </p>
                </div>
            </div>

            <Icon
                name='x'
                size={14}
                className='cursor-pointer'
            />
        </div>
    );
}

const breakLineRegex = new RegExp(/\n|<br\/>/, 'gi');

function showFeedback(message, title = null, type = 'error') {
    const feedbackMessage = message.replace(/\\n/gi, '\n');

    const contentTemplate = (element) => {
        const root = ReactDOM.createRoot(element);
        let messageLines = [getLabel('GENERIC_ERROR')];

        if (feedbackMessage && feedbackMessage.split) {
            messageLines=  feedbackMessage.split(breakLineRegex)
        }

        root.render(
            <FeedbackContent
                message={messageLines}
                title={title}
                type={type}
            />
        );
    };

    notify(
        {
            contentTemplate: contentTemplate,
            closeOnClick: true,
            displayTime: 3 * 1000,
            hideOnOutsideClick: false,
            wrapperAttr: { class: `custom-toast toast-type-${type}` },
            type,
        },
        {
            direction: 'up-push',
            position: 'bottom center',
        }
    );
}

export const showError = (message, title) => {
    showFeedback(message, title, FEEBACK_TYPE.ERROR);
};

export const showInfo = (message, title) => {
    showFeedback(message, title, FEEBACK_TYPE.INFO);
};

export const showSuccess = (message, title) => {
    showFeedback(message, title, FEEBACK_TYPE.SUCCESS);
};

export const showWarning = (message, title) => {
    showFeedback(message, title, FEEBACK_TYPE.WARNING);
};
