export function downloadFile(fileBlob, fileName) {
  const href = URL.createObjectURL(fileBlob);

  const aElement = document.createElement('a');
  aElement.setAttribute('download', fileName);
  aElement.href = href;
  aElement.setAttribute('target', '_blank');

  aElement.click();
  URL.revokeObjectURL(href);
}
