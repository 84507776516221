import clsx from 'clsx';
import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from '../../../../routes/constants';
import { Icon } from '../../../Icon/Icon';
import { OverlayContainer } from '../../../OverlayContainer/OverlayContainer';
import SimpleButtonComponent from '../../../button/simple_button';
import { BackButton } from '../../../button/BackButton';

function MenuPageLink({ onClick, to, ...props }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(to);
    onClick();
  };

  return (
    <MenuPageButton
      onClick={handleClick}
      {...props}
    />
  );
}

function MenuPageButton(props) {
  return (
    <SimpleButtonComponent
      variant='link-text'
      className='flex-1 justify-between text-lg text-start'
      {...props}
    />
  );
}

export class MobileMenu extends Component {
  constructor(props) {
    super(props);

    this.state = { navigation: [] };

    this.handleClickBackNavigation = this.handleClickBackNavigation.bind(this);
    this.handleClickCloseMenu = this.handleClickCloseMenu.bind(this);
    this.handleClickLink = this.handleClickLink.bind(this);
    this.handleClickParentPage = this.handleClickParentPage.bind(this);
    this.renderPage = this.renderPage.bind(this);
  }

  setNavigation(navigation) {
    this.setState((state) => ({ ...state, navigation }));
  }

  handleClickCloseMenu() {
    this.props.toggleMenuOpen();
    this.setNavigation([]);
  }

  handleClickBackNavigation() {
    this.setNavigation(this.state.navigation.slice(0, -1));
  }

  handleClickLink() {
    this.setNavigation([]);
    this.props.toggleMenuOpen();
  }

  handleClickParentPage(pageId) {
    this.setNavigation([...this.state.navigation, pageId]);
  }

  renderPage(page) {
    return (
      <li
        key={page.id}
        className='flex'
      >
        {page.path ? (
          <MenuPageLink
            to={PATH.BASE + page.path}
            onClick={this.handleClickLink}
          >
            <span>{page.title}</span>
          </MenuPageLink>
        ) : (
          <MenuPageButton onClick={() => this.handleClickParentPage(page.id)}>
            <span>{page.title}</span>
            <Icon
              name='seta'
              sizeMobile={18}
              className='mt-1 -rotate-90 group-[.level-0]:hidden'
            />
          </MenuPageButton>
        )}
      </li>
    );
  }

  renderSubPages() {
    const { pages = [] } = this.props;

    const parentId = this.state.navigation.slice(-1)[0];
    const parentPage = pages.find((page) => page.id === parentId);
    const subPages = pages.filter((page) => page.parentId === parentId);

    return (
      <>
        <li className='mb-4 flex text-2xl font-bold text-brand-gray700'>
          {parentPage.title}
        </li>
        {subPages.map(this.renderPage)}
      </>
    );
  }

  renderRootPages() {
    const rootPages = this.props.pages.filter((page) => !page.parentId);

    return rootPages.map(this.renderPage);
  }

  render() {
    const isNavigating = this.state.navigation.length > 0;
    const level = this.state.navigation.length;

    return (
      <OverlayContainer
        key='menu-mobile'
        isVisible={this.props.isOpen}
        className={clsx('lg:hidden', {
          'bg-neutral-200 text-brand-gray500': isNavigating,
          'bg-neutral-300 text-brand-gray700': !isNavigating,
        })}
      >
        <nav>
          <div
            className={clsx('flex h-16 flex-row items-center px-3', {
              'justify-end': !isNavigating,
              'justify-between': isNavigating,
            })}
          >
            {isNavigating && (
              <BackButton
                onClick={this.handleClickBackNavigation}
                className='underline'
              />
            )}

            <SimpleButtonComponent
              variant='icon-button'
              onClick={this.handleClickCloseMenu}
            >
              <Icon
                name='x'
                sizeMobile={20}
              />
            </SimpleButtonComponent>
          </div>

          <div className='relative h-full w-full'>
            <ul className={`group p-6 level-${level} flex flex-col gap-8`}>
              {isNavigating ? this.renderSubPages() : this.renderRootPages()}
            </ul>
          </div>
        </nav>
      </OverlayContainer>
    );
  }
}
