import React from "react";
import { Navigate } from "react-router-dom";

import LoadingComponent from "../../components/loading/load_panel";
import resourcesConfig from "../../config/api_resources.json";
import { PATH } from "../../routes/constants";
import * as auth from "../../utils/authentication/authentication";
import { caller } from "../../utils/integration/integration";
import { LOCAL_STORAGE } from "../../utils/storage/constants";
import { removeValue, setValue, getValue } from "../../utils/storage/local_storage";
import { getLabel } from "../../utils/translates/login_labels";

class LoginWithChaveMovelDigital extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            pageLoad: false,
            userIdentifier: null
        }
    }

    setSuccess = (auth) => {
        if (this.props.handleAuthentication) {
            return this.props.handleAuthentication(true, auth);
        }
    }
    
    setError = () => {
        document.location.href = PATH.LOGIN_ERROR;
    }

    authenticateWithChaveMovelDigital = (identifier) => {
        auth.authenticate({login: null, password: null, isChaveMovelDigital: true, userEncoded: identifier})
        .then(authResult => {
            this.setSuccess(authResult);
        }).catch(err => {
            this.setError();
        });
    };

    logoutChaveMovelDigital = () => {
        caller.getWithoutAuth(resourcesConfig.AUTHENTICATION.CHAVEMOVELDIGITALLOGOUTREQUEST, {redirectUrl: window.location.origin})
        .then(response => {
            var form = document.createElement("form");
            var SAMLRequest = document.createElement("input");
            var RelayState = document.createElement("input");
            
            form.method = "POST";
            form.action = response.result.action; 
            
            SAMLRequest.value = response.result.samlRequest;
            SAMLRequest.name = "SAMLRequest";
            form.appendChild(SAMLRequest);  

            RelayState.value = response.result.relayState;
            RelayState.name = "RelayState";
            form.appendChild(RelayState);

            document.body.appendChild(form);

            form.submit();
        }).catch(err => {
            this.setError(err);
        });
    };

    getURLParameters = () => {
        let params = new URLSearchParams(window.location.search);
        let userIdentifier = params.get('id');
        let logoutCMD = params.get('lcmd');
        if(logoutCMD){
            if(!userIdentifier)
                window.location.href = PATH.LOGIN;
            
            setValue(LOCAL_STORAGE.CMD_LOGIN, userIdentifier);
            
            this.logoutChaveMovelDigital();
        }else{
            userIdentifier = getValue(LOCAL_STORAGE.CMD_LOGIN);
            if(userIdentifier){
                removeValue(LOCAL_STORAGE.CMD_LOGIN);
                this.authenticateWithChaveMovelDigital(userIdentifier)
            }else{
                window.location.href = PATH.LOGIN;
            }
        }
    }

    render() {
        if (!this.state.pageLoad) 
            this.getURLParameters();

        return (
            <div className="min-h-screen flex justify-center bg-login bg-cover">
                <LoadingComponent isVisible={true} />
            </div>
        );
    }
}

export default LoginWithChaveMovelDigital;