import React from 'react';
import { Link } from 'react-router-dom';

import { PATH } from '../../routes/constants';
import { getDateFormatted } from '../../utils/format/formatNumber';
import { translate } from '../../utils/translates/translates_helper';
import { LinkButton } from '../button/LinkButton';
import { Logo } from '../image/Logo';

function LogoutLink({ className }) {
  return (
    <LinkButton
      to={PATH.LOGOUT}
      className={className}
    >
      <span className='uppercase text-white'>{translate('LOGOUT')}</span>
    </LinkButton>
  );
}

function UserAccessInfo({ userLastAccess = null, userName = '' }) {
  const lastAccessDate = userLastAccess
    ? getDateFormatted(userLastAccess, {
        type: 'yyyy-MM-dd, HH:mm',
      })
    : '-';

  return (
    <span className='text-right'>
      <p>
        {translate('WELCOME')}, {userName}
      </p>

      <p className='hidden text-zinc-400 lg:flex'>
        {translate('LastAccess')}: {lastAccessDate}
      </p>
    </span>
  );
}

export function Header({ userInfo, defaultPage }) {
  return (
    <div
      id='header-banner'
      className='flex h-24 items-center justify-between bg-brand-green500 px-4 text-white lg:h-20 lg:px-8 '
    >
      <Link to={defaultPage}>
        <Logo />
      </Link>

      <div className='flex flex-col items-end gap-1 lg:flex-row lg:items-center lg:gap-8'>
        <UserAccessInfo
          userLastAccess={userInfo['EntidadeUltimoAcesso']}
          userName={userInfo['EntidadeNome']}
        />

        <div className='flex items-center gap-1 lg:gap-4'>
          {/* TODO: <Icon name='notificacao' variant='filled' size={24} /> */}
          {/* TODO: <Icon name='mail' variant='filled' size={22} /> */}

          <LogoutLink className='lg:hidden' />
        </div>

        <LogoutLink className='hidden lg:flex' />
      </div>
    </div>
  );
}
