import { PATH } from '../../routes/constants';
import { COOKIE } from '../cookies/constants';
import * as cookiesHelper from '../cookies/cookie';
import { logoutUser } from '../integration/logout';
import { LOCAL_STORAGE } from './constants';

export function setValue(key, value) {
  localStorage.setItem(key, value);
  calculateHash(true);
}

export function setJsonValue(key, value) {
  setValue(key, JSON.stringify(value));
}

export function getValue(key) {
  checkLocalVariables();
  return localStorage.getItem(key);
}

export function getJsonValue(key) {
  return JSON.parse(getValue(key));
}

export function removeValue(key) {
  localStorage.removeItem(key);
  calculateHash(true);
}

export function getSelectedAccountId() {
  return getValue(LOCAL_STORAGE.ACCOUNT_ID);
}

function getUserDataProp(propName) {
  const data = getJsonValue(LOCAL_STORAGE.USER_DATA);

  return data?.hasOwnProperty(propName) ? data[propName] : undefined;
}

export function getSelectedAccountInfo() {
  return getUserDataProp('userAccounts');
}

export function getUserTables() {
  return getUserDataProp('appTables');
}

export function getUserCoins() {
  return getUserDataProp('appCoins');
}

export function getUserTranslates() {
  return getUserDataProp('appTranslates');
}

export function getSelectedAccount() {
  return getValue(LOCAL_STORAGE.ACCOUNT);
}

export function isCompanyAccount() {
  return JSON.parse(getValue(LOCAL_STORAGE.COMPANY_ACCOUNT));
}

export function getSelectedAccountIBAN() {
    return getValue(LOCAL_STORAGE.ACCOUNT_IBAN);
  }

export function getUserLanguage() {
  return getValue(LOCAL_STORAGE.USER_LANGUAGE);
}

export function getUserLanguageId() {
  return getValue(LOCAL_STORAGE.USER_LANGUAGE_ID);
}


export function getUserTransactionMaskedPhone() {

    const data = getUserDataProp('userInfo');
    return data?.TransaccaouTelemovelDigitos ?? null;
}


export function getTestModeEnabled() {
  return JSON.parse(getValue(LOCAL_STORAGE.TEST_MODE));
}

export function getEntity() {
  const data = getUserDataProp('userInfo');
  return data?.EntidadeID ?? null;
}

export function getManagerEmail() {
  const data = getUserDataProp('userInfo');
  return data?.ComercialEmail ?? null;
}

export function getAccessType() {
  return getValue(LOCAL_STORAGE.LOGIN_TYPE);
}

export function getLoginUserName() {
  return getValue(LOCAL_STORAGE.LOGIN_USERNAME);
}

function getIsUserPrivateBanker() {
  const data = getUserDataProp('userInfo');
  return data?.isPrivateBanker ?? false;
}

export function clearLocal() {
  localStorage.clear();
}

export function checkLocalVariables() {
  let timeDiff = Date.now() - localStorage.getItem(LOCAL_STORAGE.PREVIOUS_CHECK) ?? Date.now() - 10000;
  if (Math.floor(timeDiff / 1000) > 2) {
    calculateHash(false);
  }
}

function getExpirationTimeByNow() {
  const now = new Date();
  
  return now.getMinutes() + parseInt(process.env.REACT_APP_INACTIVITY_TIME);
}

export function startInactivityDate() {
  var inactivityDate = new Date();
  inactivityDate.setMinutes(getExpirationTimeByNow());
  setValue(LOCAL_STORAGE.INACTIVITY_DATE, inactivityDate);
}

export function getLastCall() {
  return getValue(LOCAL_STORAGE.LAST_CALL);
}

export function setLastCall(value){
  setValue(LOCAL_STORAGE.LAST_CALL, value);
}

export function isAPIProcessing() {
  return getValue(LOCAL_STORAGE.API_PROCESSING) === "1";
}

export function setAPIProcessing(value){
  setValue(LOCAL_STORAGE.API_PROCESSING, value);
}

export function refreshInactivityDate() {
  var inactivityDate = getValue(LOCAL_STORAGE.INACTIVITY_DATE);

  let inactivityDateConverted = new Date(inactivityDate);

  if (inactivityDateConverted < new Date()) {
    logoutUser(PATH.LOGIN_UNAUTHORIZED)
  } else {
    inactivityDate = new Date();
    inactivityDate.setMinutes(getExpirationTimeByNow());
    setValue(LOCAL_STORAGE.INACTIVITY_DATE, inactivityDate);
  }
}

function calculateHash(saveResult = false) {
  const localStorageKeys = Object.keys(localStorage).sort();
  
  const baseString = localStorageKeys.reduce((acc, key) => {
    if ([LOCAL_STORAGE.VALIDATION, LOCAL_STORAGE.PREVIOUS_CHECK].includes(key)) {
      return acc;
    }

    return acc.concat(localStorage.getItem(key));
  }, '')

  let base64String = btoa(hashCode(baseString));
  
  if (saveResult) {
    localStorage.setItem(LOCAL_STORAGE.VALIDATION, base64String);
  } else {
    localStorage.setItem(LOCAL_STORAGE.PREVIOUS_CHECK, Date.now());

    if (localStorage.getItem(LOCAL_STORAGE.VALIDATION) === null) {
      return;
    }
    
    if (base64String !== localStorage.getItem(LOCAL_STORAGE.VALIDATION)) {
      clearLocal();
      window.location.reload(false);
    }
  }
}

const hashCode = (s) => s.split('').reduce((a, b) => ((a << 5) - a + b.charCodeAt(0)) | 0, 0);

export const localStorageHelper = {
  checkLocalVariables,
  clearLocal,
  getJsonValue,
  getSelectedAccount,
  getSelectedAccountId,
  getSelectedAccountInfo,
  getSelectedAccountIBAN,
  isCompanyAccount,
  getUserLanguage,
  getUserLanguageId,
  getValue,
  refreshInactivityDate,
  removeValue,
  setJsonValue,
  setValue,
  getUserTables,
  getUserCoins,
  getUserTranslates,
  startInactivityDate,
  getTestModeEnabled,
  getEntity,
  getUserTransactionMaskedPhone,
  getIsUserPrivateBanker,
  getManagerEmail,
  getLastCall,
  setLastCall,
  hashCode,
  getAccessType,
  getLoginUserName,
  isAPIProcessing,
  setAPIProcessing
};
