export const COOKIE = {
    COOKIES_ALLOWED: 'cookiesAllowed',
    RESPONSE_DATASET: 'responsedataset',
    USER_EXPIRATION_TIME: 'userExpirationTime',
    USER_LANGUAGE: 'userLanguage',
    X_ACCESS_TOKEN: 'X-Access-Token',
    X_REFRESH_TOKEN: 'X-Refresh-Token',
    X_USER_LANGUAGE: 'X-User-Language',
    X_LOGINID: 'X-LoginID',
};
