import clsx from 'clsx';
import { Component, createRef } from 'react';

export class Iframe extends Component {
  constructor(props) {
    super(props);

    this.iframe = createRef();
  }

  componentDidMount() {
    const calculateResize = this.props?.calculateResize ?? true;

    if (calculateResize) {
      const contentHeight = this.iframe.current.ownerDocument.body.scrollHeight + 'px';
      this.iframe.current.style.height = contentHeight;
    }
  }

  render() {
    return (
      <iframe
        ref={this.iframe}
        className={clsx('w-full h-full overflow-hidden m-0', this.props.className)}
        src={this.props.src}
        title={this.props.title}
      />
    );
  }
}
