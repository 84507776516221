import logo from '../../assets/img/logotipo.svg';
import logoBranco from '../../assets/img/logotipo_branco.svg';

export function Logo({ variant = 'white' }) {
  const src = variant === 'white' ? logoBranco : logo;

  return (
    // If you have to change styles here, please copy it to PrintContainer.jsx
    <img
      alt='Logo'
      src={src}
      className='object-contain w-40'
    />
  );
}
