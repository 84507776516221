import { formatNumberToCurrency } from './currency_format';

export function removeSpaces(text) {
  return text.replace(/\s/g, '');
}

export function removeHtmlSpaces(text) {
  return text.replace(/ {2}|\n/g, '');
}

export function trimAround(text) {
  return text.replace(/^\s|\s$/g, '');
}

export function formatTextToIban(input) {
  if (input) {
    return removeSpaces(input).toUpperCase();
  }

  return '';
}

export function formatDecimalValue({
  value = null,
  decimals = 2,
  withPositiveSign = false,
  currency,
}) {
  if (value === null || isNaN(value)) {
    return '';
  }

  let result;

  if (currency) {
    result = formatNumberToCurrency(value, decimals, currency);
  } else {
    result = parseFloat(value).toFixed(decimals);
  }

  return withPositiveSign && result > 0 ? `+${result}` : result;
}
export function isNullEmptyOrWhiteSpace(text) {
  return text === null || (typeof text === 'string' && !removeSpaces(text).length);
}

export function isNegativeNumber(text = '') {
  return (text?.length > 1 && text.startsWith('-')) || Number(text) < 0;
}

export function isPositiveNumber(text = '') {
  return (text?.length > 1 && text.startsWith('+')) || (parseFloat(text) > 0) || (parseFloat(text.replace(',', '.')) > 0);
}

export function formatTextToNib(input) {
  if (input) {
    return removeSpaces(input).toUpperCase().replace(/PT50/, '');
  }

  return '';
}

export function getJsonFromString(jsonString) {
  try {
    var params = JSON.parse(jsonString);
    if (params && typeof params === 'object') {
      return params;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
}

export function isValueTrue(str) {
  return str === '1' || str === 'true' || str === true || str === 1;
}

export function getFunctionFromString(template) {
  return  template ? Function(template) : null;
}