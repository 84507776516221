import clsx from 'clsx';
import { Component } from 'react';

import { isPasswordAllowed, updatePassword } from '../../server/features/security';
import { showError } from '../../utils/notification/showFeedback';
import { getLabel } from '../../utils/translates/login_labels';
import { PasswordStrength } from '../PasswordStrength/PasswordStrength';
import SimpleButtonComponent from '../button/simple_button';
import { InputText } from '../input/InputText';

export const FIELD_KEY = {
    PASSWORD: 'password',
    NEW_PASSWORD: 'newPassword',
    NEW_PASSWORD_CONFIRM: 'newPassword2',
};

export const PASSWORD_FIELDS = [
    {
        name: FIELD_KEY.PASSWORD,
        labelInvalid: getLabel('INVALID_PASSWORD'),
        validate: (v) => v?.length > 0,
    },
    {
        name: FIELD_KEY.NEW_PASSWORD,
        labelInvalid: getLabel('NEW_PASSWORD_WARNING'),
        validate: (v, _, rules) => v?.length > 0 && rules.length === 0,
    },
    {
        name: FIELD_KEY.NEW_PASSWORD_CONFIRM,
        labelInvalid: getLabel('CHANGE_PASSWORD_INVALID'),
        validate: (v, form) => v === form[FIELD_KEY.NEW_PASSWORD],
    },
];

class NewPasswordForm extends Component {
    constructor(props) {
        super(props);

        let fields = Object.values(FIELD_KEY);

        if (props.isHideCurrentPasswordField) {
            fields = fields.filter((field) => field !== FIELD_KEY.PASSWORD);
        }

        this.state = {
            form: fields.reduce((acc, f) => ({ ...acc, [f]: undefined }), {}),
            invalidRules: [],
        };

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    validateForm() {
        var { form, invalidRules } = this.state;
        invalidRules = [];
        const fields = Object.keys(form);

        const invalidFields = fields.reduce((acc, fieldName) => {
            const field = PASSWORD_FIELDS.find((f) => f.name === fieldName);

            if (field.validate(form[fieldName], form, invalidRules)) {
                return acc;
            }

            return [...acc, field.labelInvalid];
        }, []);

        if (this.props.setInvalidFields) {
            this.props.setInvalidFields(invalidFields);
        }

        return invalidFields.length === 0;
    }

    sucessupdatePassword(a) {
        return function () {
            if (a.props.successCallback) {
                a.props.successCallback();
            }
        }
    }

    finalyPassword(a) {
        return function () {
            if (a.props.onLoading) {
                a.props.onLoading(false);
            }
        }

    }

    submit() {
        if (this.props.onLoading) {
            this.props.onLoading(true);
        }

        updatePassword(this.state.form, this.finalyPassword(this), this.sucessupdatePassword(this))
            .then(({ error, recordId, invalidData }) => {
                if (invalidData.length > 0) {
                    this.updateInvalidRules(invalidData);
                    return;
                }

                this.updateInvalidRules([]);

                if (error) {
                    showError(error);
                    return;
                }

                if (!recordId) {
                    showError(getLabel('CHANGE_PASSWORD_ERROR'));
                    return;
                }

                if (this.props.successCallback) {
                    this.props.successCallback();
                }
            })
            .catch(() => {
                showError(getLabel('GENERIC_ERROR'));
            })
            .finally(() => {
                if (this.props.onLoading) {
                    this.props.onLoading(false);
                }
            });
    }


    handleSubmit(event) {
        event.preventDefault();

        const isValid = this.validateForm();

        if (isValid) {
            if (this.props.onSubmit) {
                isPasswordAllowed(this.state.form[FIELD_KEY.NEW_PASSWORD]).then(({ data, isAllowed }) => {
                    if (isAllowed) {
                        return this.props.onSubmit(this.state.form);
                    } else {
                        this.updateInvalidRules(data);
                        return false;
                    }
                });
            } else {
                this.submit();
            }
        }
    }

    updateInvalidRules(rules) {
        this.setState((state) => ({ ...state, invalidRules: rules }));

        if (this.props.setInvalidRules) {
            this.props.setInvalidRules(rules);
        }
    }

    handleChangeInput(event) {
        const { name, value } = event.currentTarget;

        this.setState(
            (state) => ({
                ...state,
                form: { ...state.form, [name]: value },
            }),
            async () => {
                //if (name === FIELD_KEY.NEW_PASSWORD) {
                //  if (value.length > 0) {
                //    await isPasswordAllowed(value).then(({ data }) => {
                //      this.updateInvalidRules(data);
                //    });
                //  } else {
                //    this.updateInvalidRules([]);
                //  }
                //}
            }
        );
    }

    render() {
        return (
            <form
                id={this.props?.id ?? undefined}
                onSubmit={this.handleSubmit}
                className={clsx('flex flex-col gap-3 lg:gap-3', this.props.className)}
            >
                {!this.props.isHideCurrentPasswordField && (
                    <InputText
                        id='change-password-current-password'
                        label={getLabel('PASSWORD') + ':'}
                        name={[FIELD_KEY.PASSWORD]}
                        onChange={this.handleChangeInput}
                        type='password'
                        autoComplete='new-password'
                    />
                )}

                <InputText
                    adornment={<PasswordStrength password={this.state.form[FIELD_KEY.NEW_PASSWORD]} />}
                    id='change-password-new-password'
                    label={getLabel('NEW_PASSWORD') + ':'}
                    name={[FIELD_KEY.NEW_PASSWORD]}
                    onChange={this.handleChangeInput}
                    type='password'
                    autoComplete='new-password'
                />

                <InputText
                    id='change-password-new-password2'
                    isInvalid={
                        this.state.form[FIELD_KEY.NEW_PASSWORD] !==
                        this.state.form[FIELD_KEY.NEW_PASSWORD_CONFIRM]
                    }
                    invalidMessage={getLabel('CHANGE_PASSWORD_INVALID')}
                    label={getLabel('CONFIRM_PASSWORD') + ':'}
                    name={[FIELD_KEY.NEW_PASSWORD_CONFIRM]}
                    onChange={this.handleChangeInput}
                    type='password'
                    autoComplete='new-password'
                />

                <SimpleButtonComponent
                    variant='primary'
                    className={clsx('w-full', {
                        'lg:mt-12': this.props.isSubmitButtonFullWidth,
                        'self-end lg:w-fit': !this.props.isSubmitButtonFullWidth,
                    })}
                    type='submit'
                >
                    <span>{this.props.submitButtonLabel}</span>
                </SimpleButtonComponent>

                {this.props?.children}
            </form>
        );
    }
}

function NewPasswordRules({ invalidRules, rules }) {
    const isVisible = !!rules.length;

    return (
        isVisible && (
            <>
                <p className='leading-normal'>{getLabel('NEW_PASSWORD_LABEL')}</p>

                <br />

                {rules.map((rule) => (
                    <p
                        key={rule['RecordID']}
                        className={clsx('leading-normal', {
                            'text-invalid': invalidRules?.includes(rule['Descricao']),
                        })}
                    >
                        {rule['Descricao']}
                    </p>
                ))}
            </>
        )
    );
}

export const HomebankingRules = {
    FORBIDDEN_CHARS: ['\'', '\\']
}

export const HomebankingExclusiveRules = [
    //{ RecordID: 100, Mnemonica: "FORBIDDEN_CHARS", Descricao: `Caracteres proibidos: ${HomebankingRules.FORBIDDEN_CHARS}`, Ordem: 100 }
];

export const NewPassword = {
    Form: NewPasswordForm,
    Rules: NewPasswordRules
};
