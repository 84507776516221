import clsx from 'clsx';

export function InputLabel({ children, inputId, className = 'min-w-[12ch]', required = false }) {
  return (
    <label
      htmlFor={inputId}
      className={clsx('text-justify leading-normal', className)}
    >
      {children}{required && ' *'}
    </label>
  );
}
