export function getRequestUrl(resource) {
    var excp = window.CONFIG_OBJ.URL_EXCEPTIONS;
    var baseURL = window.CONFIG_OBJ.DEFAULT_CONFIG.GLOBAL_APP_SERVER_URL;
    if (excp)
        for (var i = 0; i < excp.length; i++) {
            if (window.location.href.includes(excp[i].ORIGIN)) {
                baseURL = excp[i].GLOBAL_APP_SERVER_URL
                break;
            }
        }
    const apiResource = resource ?? '';
    return baseURL + apiResource;
}

