import { Component } from 'react';
import { useNavigate } from 'react-router-dom';

import { InvalidFieldListFeedback } from '../components/Alert/InvalidFieldListFeedback';
import { AuthPageOperation } from '../components/AuthPageOperation/AuthPageOperation';
import { NewPassword } from '../components/NewPassword/NewPassword';
import { LinkButton } from '../components/button/LinkButton';
import { PATH } from '../routes/constants';
import { getPasswordPolicyList } from '../server/features/security';
import { showError } from '../utils/notification/showFeedback';
import { getLabel } from '../utils/translates/login_labels';
import { LanguageContextProvider } from '../contexts/LanguageContext';

class ChangePasswordPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidFields: [],
      invalidRules: [],
      isLoading: true,
      isSuccessVisible: false,
      rules: [],
    };

    this.setInvalidFields = this.setInvalidFields.bind(this);
    this.setInvalidRules = this.setInvalidRules.bind(this);
    this.setIsLoading = this.setIsLoading.bind(this);
    this.setSucessVisible = this.setSucessVisible.bind(this);
  }

  setInvalidFields(invalidFields) {
    this.setState((state) => ({ ...state, invalidFields }));
  }

  setInvalidRules(invalidRules) {
    this.setState((state) => ({ ...state, invalidRules }));
  }

  setIsLoading(isLoading) {
    this.setState((state) => ({ ...state, isLoading }));
  }

  setSucessVisible(isSuccessVisible) {
    this.setState((state) => ({ ...state, isSuccessVisible }));
  }

  componentDidMount() {
    this.loadRules();
  }

  loadRules() {
    if (!this.state.rules.length) {
      getPasswordPolicyList().then(({ data, errorMessage }) => {
        if (errorMessage) {
          showError(errorMessage);
        }

        this.setState((state) => ({
          ...state,
          rules: data,
          isLoading: false,
        }));
      });
    }
  }

  renderForm() {
    return (
      <>
        <AuthPageOperation.ContentTitle>
          {getLabel('CHANGE_PASSWORD_PAGE_TITLE')}
        </AuthPageOperation.ContentTitle>

        <NewPassword.Form
          id='change-password-form'
          setInvalidRules={this.setInvalidRules}
          submitButtonLabel={getLabel('CONFIRM')}
          successCallback={() => this.setSucessVisible(true)}
          setInvalidFields={this.setInvalidFields}
          onLoading={this.setIsLoading}
          isSubmitButtonFullWidth
        >
          <div className='flex self-center'>
            <LinkButton
              variant='underline'
              to={PATH.LOGOUT}
            >
              <span>{getLabel('BACK')}</span>
            </LinkButton>
          </div>
        </NewPassword.Form>
      </>
    );
  }

  renderPasswordRules() {
    return (
      !this.state.isSuccessVisible && (
        <div className='border border-brand-negative_number px-3 py-2 text-black'>
          <NewPassword.Rules
            rules={this.state.rules}
            invalidRules={this.state.invalidRules}
          />
        </div>
      )
    );
  }

  render() {
    return (
      <AuthPageOperation.Container
        isLoading={this.state.isLoading}
        header={getLabel('ACCESS_ACCOUNT')}
        isLanguageSelectorVisible={true}
      >
        <InvalidFieldListFeedback messages={this.state.invalidFields} />

        <AuthPageOperation.Content
          hasSecurityInformation
          isSuccessVisible={this.state.isSuccessVisible}
          successProps={{
            message: getLabel('CHANGE_PASSWORD_SUCCESS'),
            buttonLabel: getLabel('ENTER'),
            buttonOnClick: () => this.props.onNavigate(PATH.LOGOUT),
          }}
        >
          {this.renderForm()}
          {this.renderPasswordRules()}
        </AuthPageOperation.Content>
      </AuthPageOperation.Container>
    );
  }
}

export function ChangePasswordPage(props) {
  const navigate = useNavigate();

  return (
    <LanguageContextProvider>
      <ChangePasswordPageComponent {...props} onNavigate={navigate}/>
    </LanguageContextProvider>
  );
}
