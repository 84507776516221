import clsx from 'clsx';

import { InputLabel } from './InputLabel';
import { InvalidMessage } from './InvalidMessage';
import { inputClassName } from './constants';

export function InputText({
  id,
  invalidMessage = null,
  isInvalid = false,
  label = null,
  onChange,
  value = undefined,
  className = null,
  required = false,
  adornment = null,
  pattern = null,
  type = 'text',
  autoComplete = 'off',
  ...props
}) {
  return (
    <fieldset
      className={clsx('group flex gap-2', className, {
        invalid: isInvalid,
        'flex-col': !className?.includes('flex-'),
      })}
    >
      {label && (
        <InputLabel
          className='md:min-w-[22ch]'
          inputId={id}
          required={required}
        >
          {label}
        </InputLabel>
      )}

      <div className='flex flex-1 flex-col gap-1.5'>
        <input
          autoComplete={autoComplete}
          type={type}
          className={clsx(inputClassName, className)}
          id={id}
          onChange={onChange}
          value={value}
          pattern={pattern}
          required={required}
          {...props}
        />

        {adornment}

        <InvalidMessage>{invalidMessage}</InvalidMessage>
      </div>
    </fieldset>
  );
}
