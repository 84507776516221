import resourcesConfig from '../../config/api_resources.json';
import { caller } from '../../utils/integration/integration.js';
import { localStorageHelper } from '../../utils/storage/local_storage';

export function getTableById(id) {
  const tabledId = Number(id);
  const tables = localStorageHelper.getUserTables();

  return tables.filter((t) => t.tableId === tabledId);
}

export function LoadUserDataset() {
  return new Promise(function (resolve, reject) {
    caller
      .get(resourcesConfig.SESSION.SESSION_DATASET)
      .then((response) => {
        if (response.isError) reject(response.errorMessage);
        else {
          const userDataset = {
            pageResult: response.result[0].results[0],
            infoResult: response.result[1].results[0],
            accountResult: response.result[2].results[0],
            marketResult: response.result[3].results[0],
            translateResult: response.result[4].results[0],
            tableResult: response.result[5].results[0],
            coinsResult: response.result[6].results[0]
          };

          resolve({ userDataset });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}
