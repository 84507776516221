import clsx from 'clsx';

import { InputLabel } from './InputLabel';
import { InvalidMessage } from './InvalidMessage';
import { inputClassName } from './constants';

export function InputNumber({
  id,
  className = null,
  containerClassName = null,
  invalidMessage = null,
  isInvalid = false,
  label = null,
  onChange,
  stepValue = null,
  minimumValue = null,
  maximumValue = null,
  value = null,
  required = false,
  useMask = false,
  decimals = 2,
  currencyPrint = false,
  numberSide = "left",
  currency = null,
  removeSelectionRule = false,
  ...props
}) {
  value = useMask ? formatStartValue(value,decimals) : value;
  return (
    <fieldset
      className={clsx('group flex flex-col gap-2', containerClassName, {
        invalid: isInvalid,

      })}
    >
      {label && <InputLabel inputId={id} required={required}>{label}</InputLabel>}

      <div className='flex flex-1 flex-col gap-1.5'>
        <div className='flex items-center'>
          {currencyPrint && <span className={clsx('block absolute text-center pointer-events-none font-bold mx-2')}>{currency}</span>}
          <input
            type="text"
            className={clsx(inputClassName, className, numberSide === "left" ? "text-left" : "text-right")}
            id={id}
            inputMode="numeric"
            onInput={(e) => {e.target.value = e.target.value.replace(/\D+/g, '')}}
            onChange={(e) => handleOnChange(e, onChange, useMask, decimals, value)}
            onBeforeInput={(e) => handleFocus(e, removeSelectionRule)}
            onFocus={(e) => handleFocus(e, removeSelectionRule)}
            onClick={(e) => handleFocus(e, removeSelectionRule)}
            onBlur={(e) => handleFocusOut(e, minimumValue, maximumValue, stepValue, onChange, useMask, decimals)}
            value={value}
            required={required}
            {...props}
          />
        </div>
        

        <InvalidMessage>{invalidMessage}</InvalidMessage>
      </div>
    </fieldset>
  );
}

function handleFocusOut(e, min, max, step, onChange, useMask, decimals){
  let convertedValue = parseFloat(e.target.value.replaceAll(".","").replace(",","."));

  if(min && isNaN(convertedValue)){
    e.target.value = min;
    return handleOnChange(e, onChange, useMask, decimals)
  }
  if(min && convertedValue < min){
    e.target.value = min;
    return handleOnChange(e, onChange, useMask, decimals)
  }
  if(max && convertedValue > max){
    e.target.value = max;
    return handleOnChange(e, onChange, useMask, decimals)
  }
  if(step && convertedValue % step !== 0){
    e.target.value = Math.round(convertedValue / step) * step;
    return handleOnChange(e, onChange, useMask, decimals)
  }
}

function isTextSelected(input) {
  if (typeof input.selectionStart == "number") {
      return input.selectionStart == 0 && input.selectionEnd == input.value.length;
  } else if (typeof document.selection != "undefined") {
      input.focus();
      return document.selection.createRange().text == input.value;
  }
}

function handleFocus(e, removeSelectionRule){
  if(removeSelectionRule)
    return;
    
  let endOfCursor = e.target.value.length;
  if (!isTextSelected(e.target))
    e.target.setSelectionRange(endOfCursor, endOfCursor);
}

function handleOnChange(e, onChange, useMask, decimals = 2, value) {
  if(formatDisplayNumber(e.target.value, decimals) === value)
    return null;

  if(!useMask){
    if(typeof onChange === "function")
      return onChange(e);
    else
      return;
  }

  //Número não pode ter mais de 14 digitos, erro no Chrome
  if(e.target.value.length > 14)
    return null;

  let displayValue = formatDisplayNumber(e.target.value, decimals);
  let convertedValue = parseFloat(displayValue.replaceAll(".","").replace(",","."));

  return onChange({
    displayValue: displayValue,
    convertedValue: convertedValue
  });
}

function formatStartValue(value, decimals){
  if(value === '')
    return value;
    
  if(isNaN(value))
    return formatDisplayNumber(value.toString(), decimals);
  else
    return formatDisplayNumber(decimals === 0 ? value.toString() : value.toFixed(decimals).toString(), decimals);
}

function formatDisplayNumber(value, decimals){
  //Remove tudo que não é número e adiciona as casas decimais
  let valueWithDecimal = value.replace(/[^\d]/g,'');
  if(decimals > 0){
    valueWithDecimal = valueWithDecimal.replace(/^0+/, '');
    valueWithDecimal = valueWithDecimal.padStart(decimals + 1, '0');
    valueWithDecimal = valueWithDecimal.replace(decimalPlacesAsRegex(decimals),',$1');
  }
    
  //Separa a parte inteira e decimal
  let valueSplitted = valueWithDecimal.split(",");
  //Separa a parte inteira de 3 em 3 para adicionar o . no milhar
  valueSplitted[0] = valueSplitted[0].split(/(?=(?:\d{3})+$)/).join(".");
  //Define o value que vai no display
  let displayValue = valueSplitted.join();
  return displayValue;
}

function decimalPlacesAsRegex(decimals) {
  let regex = '';
  for(let i = 0; i < decimals; i++) {
    regex += "\\d";
  }
  return new RegExp(`(${regex}?)$`);
}