import React from 'react';
import { useNavigate } from 'react-router-dom';

import { PATH } from '../../routes/constants';
import { isAuthenticated } from '../../utils/authentication/authentication';
import { getJsonFromString } from '../../utils/format/text';
import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';
import SimpleButtonComponent from '../button/simple_button';

function Container({ children }) {
  return (
    <div id='page-title-container' className='flex items-center justify-between border-b pb-2 border-gray-500'>
      {children}
    </div>
  );
}

function Heading({ children }) {
  return (
    <p id='page-title' className='text-brand-green500 text-2xl md:text-4xl'>
      {children}
    </p>
  );
}

function PageTitleComponent({ customParams, title }) {
  const navigate = useNavigate();

  const refreshCallback = () => {
    if (!isAuthenticated()) {
      return navigate(PATH.LOGOUT);
    }

    if (window?.refreshComponent?.handleRefresh) {
      window.refreshComponent.handleRefresh();
    }
  };

  const params = customParams ? getJsonFromString(customParams) : null;
  let isRefresh = params && params.hasOwnProperty('isRefresh') && Boolean(params.isRefresh);

  return (
    <Container>
      <Heading>{title}</Heading>

      {isRefresh && (
        <SimpleButtonComponent
          variant='icon-button'
          className='text-brand-green500 hover:opacity-100 noPrint'
          onClick={refreshCallback}
        >
          <Icon
            alt={translate('UPDATE')}
            name='atualizar'
            sizeMobile={28}
            size={36}
          />
        </SimpleButtonComponent>
      )}
    </Container>
  );
}

export const PageTitle = {
  Heading: Heading,
  Component: PageTitleComponent,
  Container: Container,
};
