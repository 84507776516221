import clsx from 'clsx';
import { SelectBox } from 'devextreme-react/select-box';
import 'devextreme/dist/css/dx.light.css';
import React, { Component } from 'react';

import { LOCAL_STORAGE } from '../../utils/storage/constants';
import { localStorageHelper } from '../../utils/storage/local_storage';
import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';
import { OverlayContainer } from '../OverlayContainer/OverlayContainer';
import SimpleButtonComponent from '../button/simple_button';
import './account_selector.css';

function AccountInfo({ accountCode = '', accountHolder = null, iban = '' }) {
  return (
    <>
      <p className='leading-normal'>
        {translate('ACCOUNTNUMBER')}:&nbsp;
        <span className='font-bold'>{accountCode}</span>
      </p>
      
      {accountHolder && <p className='leading-normal'>
        {translate('ACCOUNT_HOLDER')}:&nbsp;
        <span className='font-bold'>{accountHolder}</span>
      </p>}
      
      {iban && <p className='leading-normal'>
        {translate('IBAN')}:&nbsp;
        <span className='font-bold'>{iban}</span>
      </p>}
    </>
  );
}

function AccountCard({ account, isSelect = false }) {
  return (
    <div
      className={clsx('px-4 py-2', {
        'border border-brand-green500 bg-transparent': isSelect,
        'bg-white': !isSelect,
      })}
    >
      <AccountInfo {...account} />
    </div>
  );
}

class AccountSelectorMobile extends Component {
  constructor(props) {
    super(props);

    this.state = { isOpen: false };

    this.toggleIsOpen = this.toggleIsOpen.bind(this);
  }

  setIsOpen(isOpen) {
    this.setState((state) => ({ ...state, isOpen }));
  }

  toggleIsOpen() {
    this.setIsOpen(!this.state.isOpen);
  }

  handleClickOption(account) {
    this.setIsOpen(false);
    this.props.onChange(account.accountCode);
  }

  render() {
    const accounts = [...this.props.userAccounts];
    const accountIndex = accounts.findIndex((a) => a.accountCode === this.props.value);
    const selectedAccount = accounts.splice(accountIndex, 1)[0];

    return (
      <>
        <OverlayContainer
          key='account-selector'
          isVisible={this.state.isOpen}
          className='flex flex-col gap-4 bg-brand-gray350 px-3 lg:px-6'
        >
          <div className='flex h-14 items-center justify-end'>
            <SimpleButtonComponent
              variant='icon-button'
              onClick={this.toggleIsOpen}
            >
              <Icon name='trocar' />
            </SimpleButtonComponent>
          </div>

          <AccountCard
            account={selectedAccount}
            isSelect
          />

          <ul className='contents text-brand-green500'>
            {accounts.map((account) => (
              <li
                key={account.accountCode}
                onClick={() => this.handleClickOption(account)}
              >
                <AccountCard account={account} />
              </li>
            ))}
          </ul>
        </OverlayContainer>

        {!this.state.isOpen && (
          <div className='flex w-full items-center justify-between lg:hidden'>
            <p className='text-base font-bold'>
              {translate('ACCOUNTNUMBER')}:&nbsp;{selectedAccount?.accountCode ?? ''}
            </p>

            <SimpleButtonComponent
              variant='icon-button'
              onClick={this.toggleIsOpen}
            >
              <Icon name='trocar' />
            </SimpleButtonComponent>
          </div>
        )}
      </>
    );
  }
}

class AccountSelector extends React.Component {
  accounts = [];

  constructor(props) {
    super(props);

    this.accounts = props.accounts
      .map((account) => {
        const { ContaCodigo, ContaRepresentanteNome, ContaID, ContaSinonimoCodigo, HasMarginSubAccount, ContaRepresentanteTipoClasse } = account;

        return ({
          accountCode: ContaCodigo,
          accountHolder: ContaRepresentanteNome,
          companyAccount: ContaRepresentanteTipoClasse,
          accountId: ContaID,
          hasMarginSubAccount: HasMarginSubAccount,
          iban: ContaSinonimoCodigo,
          title: ContaCodigo + ' - ' + ContaRepresentanteNome + ' - IBAN: ' + ContaSinonimoCodigo,
        })
      })
      .filter((v, i, a) => a.findIndex((v2) => v2.accountCode === v.accountCode) === i);

    this.state = {
      selectedAccount: props.value ?? this.registerFirstAccount(),
    };

    this.selectAccount = this.selectAccount.bind(this);
    this.handleChangeSelectValue = this.handleChangeSelectValue.bind(this);
  }

  handleChange({ accountCode, accountId, iban, hasMarginSubAccount, companyAccount }) {
    localStorageHelper.setValue(LOCAL_STORAGE.ACCOUNT, accountCode);
    localStorageHelper.setValue(LOCAL_STORAGE.ACCOUNT_ID, accountId);
    localStorageHelper.setValue(LOCAL_STORAGE.ACCOUNT_IBAN, iban);
    localStorageHelper.setValue(LOCAL_STORAGE.HAS_MARGIN_ACCOUNT, hasMarginSubAccount);
    localStorageHelper.setValue(LOCAL_STORAGE.COMPANY_ACCOUNT, companyAccount);

    this.props.onChange(accountCode);
  }

  registerFirstAccount() {
    if (this.accounts === undefined || this.accounts.length === 0) {
      return null;
    }

    const account = this.accounts[0];
    this.handleChange(account);

    return account.accountCode;
  }

  selectAccount(accountCode) {
    if (accountCode !== this.state.selectedAccount) {
      this.setState(
        (state) => ({ ...state, selectedAccount: accountCode }),
        function () {
          const account = this.accounts.find((a) => a.accountCode === accountCode);

          this.handleChange(account);
        }
      );
    }
  }

  renderDisplay(item) {
    let text = null;

    if (item?.accountCode) {
      text = `${translate('AccountNumber')}: ${item.accountCode}`;
    }
    
    if (item?.accountHolder) {
      text += ` - ${translate('ACCOUNT_HOLDER')}: ${item.accountHolder}`;
    }

    return item && text;
  }

  renderItem(data) {
    return <AccountInfo {...data} />;
  }

  handleChangeSelectValue(e) {
    this.selectAccount(e.value);
  }

  render() {
    const account = this.accounts.find(account => account.accountCode === this.state.selectedAccount);
    const title = this.renderDisplay(account);

    return (
      <>
        <AccountSelectorMobile
          userAccounts={this.accounts}
          onChange={this.selectAccount}
          value={this.state.selectedAccount}
        />

        <div className='noHover hidden max-w-fit bg-brand-gray300 lg:inline'>
          <SelectBox
            dataSource={this.accounts}
            valueExpr='accountCode'
            width={540}
            displayExpr={this.renderDisplay}
            value={this.state.selectedAccount}
            onValueChanged={this.handleChangeSelectValue}
            itemRender={this.renderItem}
            dropDownOptions={{ height: this.accounts.length === 1 ? 80 : 140 }}
            className='drpContas'
            elementAttr={{ class: 'border-none', title: title }}
          />
        </div>
      </>
    );
  }
}

function AccountSelectorContainer({ selectedAccount, onSelectAccount, ...props }) {
  return (
    <AccountSelector
      {...props}
      value={selectedAccount}
      onChange={onSelectAccount}
    />
  );
}

export default AccountSelectorContainer;
