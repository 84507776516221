import clsx from 'clsx';
import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';

import notFoundImage from '../assets/img/404.png';
import { LinkButton } from '../components/button/LinkButton';
import { PATH } from '../routes/constants';
import { showWarning } from '../utils/notification/showFeedback';
import { translate } from '../utils/translates/translates_helper';

function NotFoundPage(pages) {
  const navigate = useNavigate();
  const isPageListVisible = !!pages?.pages?.length;
  let pagesSorted = [];

  useEffect(() => {
    if (isPageListVisible){
      showWarning(translate("RESOURCE_NOT_FOUND"));
      pagesSorted = pages?.pages?.sort((a, b) => {
        let fa = a.order,
          fb = b.order;
    
        if (fa < fb) {
          return -1;
        }
    
        if (fa > fb) {
          return 1;
        }
    
        return 0;
      });
  
      let page = pagesSorted.find(x => x.defaultPage === 1) ?? pagesSorted[0];
      
      navigate({
        pathname: PATH.BASE + page.path
      });
    }
  }, []); 
    
  return (
    <div className={clsx({ 'w-screen h-screen flex items-center justify-center': !isPageListVisible })}>
      <div className='flex flex-col gap-4 lg:gap-5'>
        <p id='page-title' className='text-brand-green500 text-4xl border-b border-gray-500 pb-2'>
          {translate('NOT_FOUND_PAGE')}
        </p>

        <div className='flex flex-col md:flex-row items-center'>
          <img alt='Not Found' src={notFoundImage} className='object-contain h-40' />
          <div className='flex flex-col md:mx-8 gap-2 md:gap-4 text-center md:text-start'>
            <span className='text-brand-green500 text-4xl'>404</span>
            <span className='text-xl'>{translate('PAGE_DOESNT_EXISTS')}</span>

            {isPageListVisible ? (
              <span className='text-lg'>{translate('SEE_PAGES_BELOW')}</span>
            ) : (
              <LinkButton variant='underline' to={PATH.LOGIN}>{translate('BACK')}</LinkButton>
            )}
          </div>
        </div>

        {isPageListVisible && (
          <ul className='columns-2 md:columns-3 bg-brand-gray100 p-3'>
            {pagesSorted
              .filter((page) => page.path)
              .map((page) => (
                <li key={page.path} className='hover:underline text-brand-green500'>
                  <Link to={PATH.BASE + page.path}>{page.title}</Link>
                </li>
              ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default NotFoundPage;
