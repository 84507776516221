import resourcesConfig from '../config/api_resources.json';
import { getRequestUrl } from '../utils/format/api';
import { caller, encryptData, importPublicKey, postWithoutAuth } from '../utils/integration/integration';
import { getLabel } from '../utils/translates/login_labels';

export async function validateRecoveryLink(id, uId) {
  let errorMessage = null;
  let isValid = null;

  await caller
    .getWithoutAuth(resourcesConfig.AUTHENTICATION.VALIDATE_RECOVERY_LINK, {
      id,
      uId,
    })
    .then(({ errorMessage, result }) => {
      if (errorMessage) {
        errorMessage = getLabel('GENERIC_ERROR');
      } else {
        const data = result?.results[0][0] ?? [];
        isValid = !Boolean(data?.FlagExpirado);
      }
    })
    .catch(() => {
      errorMessage = getLabel('GENERIC_ERROR');
    });

  return { errorMessage, isValid };
}

export async function saveRecoveryAccess(operationType, id, uId, password = '') {
  let errorMessage = null;
  let recordId = null;
  let isValid = false;

  await validateRecoveryLink(id, uId).then((response) => {
    errorMessage = response.errorMessage;
    isValid = response.isValid;
  });

  if (isValid) {

    /*const publicKeyUrl = getRequestUrl(resourcesConfig.AUTHENTICATION.PUBLIC_KEY);
    const response = await fetch(publicKeyUrl).then((res) => res.text());
    const publicKey = await importPublicKey(response);
    const encryptedpw = await encryptData(password, publicKey);*/

    await postWithoutAuth(resourcesConfig.AUTHENTICATION.RECOVERY_PASSWORD, {
      Type: operationType,
      Parameters: JSON.stringify({
        RecordID: id,
        Key: uId,
      }),
      Password: password
    })
      .then((response) => {
        errorMessage = response?.errorMessage ?? null;
        recordId = response?.result?.results[0][0] ?? null;
      })
      .catch((err) => {
        if (err.message) {
          errorMessage = 'GENERIC_ERROR';
        }
      });
  }

  return { errorMessage, recordId };
}
