import React, { useEffect } from 'react';

import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';

const INVALID_FIELD_LIST_ID = 'page-invalid-field-list';

export function InvalidFieldListFeedback({
  messages,
  translatable = false,
  automaticScroll = true,
  title = undefined,
}) {
  useEffect(() => {
    if (messages.length > 0) {
      const list = document.getElementById(INVALID_FIELD_LIST_ID);
      automaticScroll && window.scrollTo({ top: list?.offsetTop ?? 0 });
    }
  }, [messages, automaticScroll]);

  return (
    messages?.length > 0 && (
      <div className='border border-brand-negative_number bg-white px-1.5 py-3 text-brand-negative_number max-h-60 overflow-auto'>
        {title && <p className='mb-2'>{title}:</p>}

        <ul
          id={INVALID_FIELD_LIST_ID}
          className='flex flex-col gap-3'
        >
          {messages.map((message, index) => (
            <li
              className='flex gap-1.5'
              key={index}
            >
              <Icon
                name='erro'
                size={20}
              />
              {translatable ? translate(message) : message}
            </li>
          ))}
        </ul>
      </div>
    )
  );
}
