import React from "react";

import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';
import Popup, { ToolbarItem } from 'devextreme-react/popup';
import ScrollView from 'devextreme-react/scroll-view';
import SimpleButtonComponent from '../button/simple_button';
import "./dialog.css";
import 'devextreme/dist/css/dx.light.css';
import { isUserOnMobile } from "../../utils/device/breakpoints";
import { PrintContainer } from "../PrintContainer/PrintContainer";

class DialogComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            key: Date.now(),
            loading: true,
            isError: null,
            isPrinting: false,
            dialogComponent: null
        };
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    updateDimensions() {
		this.setState({
			...this.state,
			key: Date.now()
		});
	}

    componentDidMount() {
        const query = matchMedia('(max-width: 640px)');
        query.addEventListener("change", this.updateDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener("change", this.updateDimensions);
	}

    printDocument = () => {
        this.setState(state => ({ ...state, isPrinting: true }));
    }

    renderPrintOptions = () => {
        if(this.props.isPrintable){
            return(<div>
                <SimpleButtonComponent variant='link-text' onClick={this.printDocument}>
                    <Icon name='impressora' />
                    <span>{translate("PRINT")}</span>
                </SimpleButtonComponent>
            </div>);
        }
    }

    
    renderPopup = (content) => {
        return (
            <div className="flex flex-col">
                <div id="dialog-content" className="grid content-center">
                    {content}
                </div>
                <div className="flex flex-col w-full items-end gap-2">
                    {this.renderPrintOptions()}
                </div>
            </div>
        );
    }

    handlePopupHidden = (isCloseClicked = false) => {
        this.setState(state => ({
            ...state,
            isError: null
        }), this.props.callback(isCloseClicked));
    }

    renderTitle = () => {
        const isCloseVisible = this.props.showCloseButton ?? true;

        return (
            <div className="flex align-middle gap-2 items-center">
                <h4 className="flex flex-1 justify-center">
                    {this.props.dialog ? this.props.dialog.title : null}
                </h4>
                {isCloseVisible && (
                    <SimpleButtonComponent variant='icon-button' onClick={() => this.handlePopupHidden(true)} className='text-gray-400'>
                        <Icon name='x' alt={translate('CLOSE')} size={20} />
                    </SimpleButtonComponent>
                )}
            </div>
        );
    }

    handleContentReady = (e) => {
        this.setState({
            ...this.state,
            dialogComponent: e.component
        });
    }

    handleOpenDialog = (e) => {
        if(this.state.dialogComponent)
            this.state.dialogComponent.repaint();
    }

    renderToolbarItems() {
        const isToolbarItems = !!this.props.dialog?.toolbarItems;

        return isToolbarItems ? this.props.dialog.toolbarItems.map((props, index) => (
            <ToolbarItem key={index} {...props} />
        )) : null;
    }

    renderButtons = () => {
        if (!this.props.buttons)
            return null;

        return (
            <div className='flex flex-col md:flex-row justify-end self-end'>
                {this.props.buttons.map(button => {
                    return (
                        <SimpleButtonComponent disabled={button.disabled ?? false} variant={button.variant} onClick={button.onClick} className={button.className} key={button.label}>
                            <span>{button.label}</span>
                        </SimpleButtonComponent>
                    );
                })}
            </div>);
    }

    getMobileDialogHeight = () => {
        let header = document.getElementById("header-banner");
        let nav = document.getElementById("nav-selector");
        if (nav === null || header === null) {
            return '80%'
        } else {
            let headerHeight = header.offsetHeight;
            let navHeight = nav.offsetHeight;
            let xsHeight = window.innerHeight - headerHeight - navHeight;
            return xsHeight;
        }
    }

    loadDialog = () => {
        const { width = '50%', widthXs = '100%', height = '50%', heightXs = this.getMobileDialogHeight() } = this.props;
        
        const dialogProperties = {
            id: 'dialog-container',
            class: 'dialog-container'
        }

        const isMobile = isUserOnMobile();
        const content = isMobile ? (this.props.dialog.xsContent ? this.props.dialog.xsContent : this.props.dialog.content) : this.props.dialog.content;
        
        return (
            <>
                {this.state.isPrinting && (
                    <PrintContainer
                        key={Date.now()}
                        title={this.props.customPrintTitle}
                        content={content}
                        callback={() => this.setState(state => ({ ...state, isPrinting: false }))}
                    />
                )}

                <Popup
                    width={isMobile ? widthXs : width}
                    height={isMobile ? heightXs : height}
                    position={isMobile ? "bottom" : null}
                    wrapperAttr={dialogProperties}
                    showTitle={true}
                    shading={true}
                    shadingColor={isMobile ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)'}
                    titleRender={this.renderTitle}
                    dragEnabled={false}
                    hideOnOutsideClick={this.props.hideOnOutsideClick ?? true}
                    visible={this.props.visible}
                    showCloseButton={this.props.showCloseButton ?? true}
                    onHiding={() => this.handlePopupHidden(false)}
                    onContentReady={this.handleContentReady}
                >
                    
                    <ScrollView width='100%' height='100%'>
                        {this.renderPopup(content)}
                    </ScrollView>

                    {this.renderButtons()}
                    {this.renderToolbarItems()}
                </Popup>
            </>
        );
    }

    render() {
        return this.loadDialog();
    }
}

export default DialogComponent;