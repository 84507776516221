import clsx from 'clsx';
import { useEffect } from 'react';
import { createPortal } from 'react-dom';

export const OVERLAY_CONTAINER_ID = 'app-over-container';

export function OverlayContainer({ children, className = '', id, isVisible = false }) {
  const getContainer = () => document.getElementById(OVERLAY_CONTAINER_ID);

  useEffect(() => {
    const container = getContainer();

    if (isVisible) {
      container?.classList.remove('invisible');
      //container?.classList.remove(isFullHeight ? 'h-full' : 'h-[calc(100%+3.5rem)]');
      //container?.classList.add(isFullHeight ? 'h-[calc(100%+3.5rem)]' : 'h-full');
    } else {
      container?.classList.add('invisible');
    }
  }, [isVisible]);

  const container = getContainer();

  return container && isVisible
    ? createPortal(
        <div
          className={clsx('h-full w-full', className, {
            'bg-brand-gray350': !className.includes('bg-'),
          })}
        >
          {children}
        </div>,
        container,
        id
      )
    : null;
}
