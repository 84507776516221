import resourcesConfig from '../config/api_resources.json';
import { get } from '../utils/integration/integration';
import { translate } from '../utils/translates/translates_helper';

export async function getEntityForms() {
  let errorMessage = '';
  let data = [];

  await get(resourcesConfig.ACCOUNT.GET_ENTITY_FORMS)
    .then((response) => {
      data = response.result?.results ?? [];
    })
    .catch((err) => {
      errorMessage = translate(err);
    });

  return { errorMessage, data };
}
