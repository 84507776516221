import { Component } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { InvalidFieldListFeedback } from '../components/Alert/InvalidFieldListFeedback';
import { AuthPageOperation } from '../components/AuthPageOperation/AuthPageOperation';
import { JsxFromString } from '../components/JsxFromString/JsxFromString';
import { FIELD_KEY, NewPassword } from '../components/NewPassword/NewPassword';
import { LinkButton } from '../components/button/LinkButton';
import SimpleButtonComponent from '../components/button/simple_button';
import { useQueryStrings } from '../hooks/useQueryStrings';
import { PATH } from '../routes/constants';
import { saveRecoveryAccess, validateRecoveryLink } from '../server/authentication';
import { getPasswordPolicyList } from '../server/features/security';
import { showError } from '../utils/notification/showFeedback';
import { getLabel } from '../utils/translates/login_labels';

const RECOVERY_OPERATION = {
  PWDBLP: 'PWDBLP',
  PWDBLU: 'PWDBLU',
  PWDCAN: 'PWDCAN',
  PWDSUB: 'PWDSUB',
};

function showOperationError(errorMessage) {
  const title = getLabel('RECOVERY_OPERATION_ERROR_TITLE');
  const sufix = getLabel('RECOVERY_OPERATION_ERROR_SUFIX');
  const message = `${getLabel(errorMessage)} ${sufix}`;

  showError(message, title);
}

class RecoveryPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invalidFields: [],
      invalidRules: [],
      isSuccessVisible: false,
      rules: [],
    };

    this.saveOperation = this.saveOperation.bind(this);
    this.setInvalidFields = this.setInvalidFields.bind(this);
    this.setInvalidRules = this.setInvalidRules.bind(this);
    this.setSucessVisible = this.setSucessVisible.bind(this);
  }

  setInvalidFields(invalidFields) {
    this.setState((state) => ({ ...state, invalidFields }));
  }

  setInvalidRules(invalidRules) {
    this.setState((state) => ({ ...state, invalidRules }));
  }

  setSucessVisible(isSuccessVisible) {
    this.setState((state) => ({ ...state, isSuccessVisible }));
  }

  componentDidMount() {
    this.loadRules();
  }

  loadRules() {
    if (!this.state.rules.length) {
      getPasswordPolicyList().then(({ data, errorMessage }) => {
        if (errorMessage) {
          showError(errorMessage);
        }

        this.setState((state) => ({ ...state, rules: data }));
      });
    }
  }

  saveOperation(form) {
    this.props.setIsLoading(true);

    saveRecoveryAccess(
      RECOVERY_OPERATION.PWDSUB,
      this.props.id,
      this.props.uid,
      form[FIELD_KEY.NEW_PASSWORD]
    )
      .then(({ errorMessage, recordId }) => {
        if (errorMessage || !recordId) {
          showOperationError('CHANGE_PASSWORD_ERROR');
        } else {
          this.setState((state) => ({ ...state, isSuccessVisible: true }));
        }
      })
      .catch(() => {
        showOperationError('CHANGE_PASSWORD_ERROR');
      })
      .finally(() => {
        this.props.setIsLoading(false);
      });
  }

  renderForm() {
    return (
      <>
        <AuthPageOperation.ContentTitle>
          {getLabel('RECOVERY_CHANGE_PASSWORD_TITLE')}
        </AuthPageOperation.ContentTitle>

        <AuthPageOperation.ContentDescription>
          <JsxFromString domString={getLabel('RECOVERY_CHANGE_PASSWORD_TEXT')} />
        </AuthPageOperation.ContentDescription>

        <NewPassword.Form
          id='reset-password-form'
          setInvalidRules={this.setInvalidRules}
          submitButtonLabel={getLabel('CONFIRM')}
          successCallback={() => this.setSucessVisible(true)}
          setInvalidFields={this.setInvalidFields}
          isSubmitButtonFullWidth
          isHideCurrentPasswordField
          onSubmit={this.saveOperation}
        />
      </>
    );
  }

  renderPasswordRules() {
    return (
      !this.state.isSuccessVisible && (
        <div className='border border-brand-negative_number px-3 py-2 text-black'>
          <NewPassword.Rules
            rules={this.state.rules}
            invalidRules={this.state.invalidRules}
          />
        </div>
      )
    );
  }

  render() {
    return (
      <>
        <InvalidFieldListFeedback messages={this.state.invalidFields} />

        <AuthPageOperation.Content
          hasSecurityInformation
          isSuccessVisible={this.state.isSuccessVisible}
          successProps={{
            message: getLabel('RECOVERY_CHANGE_PASSWORD_SUCCESS'),
            buttonLabel: getLabel('ENTER'),
            buttonOnClick: () => this.props.onNavigate(PATH.LOGIN),
          }}
        >
          {this.renderForm()}
          {this.renderPasswordRules()}
        </AuthPageOperation.Content>
      </>
    );
  }
}

class RecoveryPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      isSuccessVisible: false,
      isValid: undefined,
    };

    this.setIsLoading = this.setIsLoading.bind(this);
  }

  setIsLoading(isLoading) {
    this.setState((state) => ({ ...state, isLoading }));
  }

  componentDidMount() {
    this.validateUrlInfo();
  }

  validateUrlInfo() {
    validateRecoveryLink(this.props.id, this.props.uid).then(({ isValid }) => {
      this.setState((state) => ({
        ...state,
        isValid: isValid,
        isLoading: false,
        isSuccessVisible: false,
      }));
    });
  }

  saveOperation(operationErrorMessage) {
    this.setState((state) => ({ ...state, isLoading: true }));

    saveRecoveryAccess(this.props.operation, this.props.id, this.props.uid)
      .then(({ errorMessage, recordId }) => {
        if (errorMessage || !recordId) {
          showOperationError(operationErrorMessage);
        } else {
          this.setState((state) => ({ ...state, isSuccessVisible: true }));
        }
      })
      .catch(() => {
        showOperationError(operationErrorMessage);
      })
      .finally(() => {
        this.setState((state) => ({ ...state, isLoading: false }));
      });
  }

  renderContent({ description, errorMessage, successMessage, title }) {
    return (
      <AuthPageOperation.Content
        hasSecurityInformation
        isSuccessVisible={this.state.isSuccessVisible}
        successProps={{
          message: getLabel(successMessage),
          buttonLabel: getLabel('ENTER'),
          buttonOnClick: () => this.props.onNavigate(PATH.LOGIN),
        }}
      >
        <>
          <AuthPageOperation.ContentTitle>{getLabel(title)}</AuthPageOperation.ContentTitle>

          <AuthPageOperation.ContentDescription>
            <JsxFromString domString={getLabel(description)} />
          </AuthPageOperation.ContentDescription>

          <SimpleButtonComponent
            variant='primary'
            onClick={() => this.saveOperation(errorMessage, title)}
          >
            {getLabel('CONFIRM')}
          </SimpleButtonComponent>
        </>
      </AuthPageOperation.Content>
    );
  }

  renderRevokeRequest() {
    return this.renderContent({
      description: 'RECOVERY_REVOKE_REQUEST_TEXT',
      errorMessage: 'RECOVERY_REVOKE_REQUEST_ERROR',
      title: 'RECOVERY_REVOKE_REQUEST_TITLE',
      successMessage: 'RECOVERY_REVOKE_REQUEST_SUCCESS',
    });
  }

  renderBlockUser() {
    return this.renderContent({
      description: 'RECOVERY_BLOCK_USER_TEXT',
      errorMessage: 'RECOVERY_BLOCK_USER_ERROR',
      title: 'RECOVERY_BLOCK_USER_TITLE',
      successMessage: 'RECOVERY_BLOCK_USER_SUCCESS',
    });
  }

  renderRemoveRequest() {
    return this.renderContent({
      description: 'RECOVERY_REMOVE_REQUEST_TEXT',
      errorMessage: 'RECOVERY_REMOVE_REQUEST_ERROR',
      title: 'RECOVERY_REMOVE_REQUEST_TITLE',
      successMessage: 'RECOVERY_REMOVE_REQUEST_SUCCESS',
    });
  }

  renderChangePassword() {
    return (
      <RecoveryPassword
        id={this.props.id}
        onNavigate={this.props.onNavigate}
        setIsLoading={this.setIsLoading}
        uid={this.props.uid}
      />
    );
  }

  renderInvalidLinkInfo() {
    return (
      <AuthPageOperation.Content>
        <>
          <AuthPageOperation.ContentTitle>
            {getLabel('RECOVERY_EXPIRED_LINK_TITLE')}
          </AuthPageOperation.ContentTitle>

          <AuthPageOperation.ContentDescription>
            {getLabel('RECOVERY_EXPIRED_LINK')}
          </AuthPageOperation.ContentDescription>

          <div className='flex justify-center'>
            <LinkButton
              variant='underline'
              to={PATH.LOGIN}
            >
              <span>{getLabel('BACK')}</span>
            </LinkButton>
          </div>
        </>
      </AuthPageOperation.Content>
    );
  }

  render() {
    return (
      <AuthPageOperation.Container
        isLoading={this.state.isLoading}
        header={getLabel('ACCESS_RECOVERY')}
      >
        {this.state.isValid === true ? (
          <>
            {this.props.operation === RECOVERY_OPERATION.PWDBLP && this.renderRevokeRequest()}
            {this.props.operation === RECOVERY_OPERATION.PWDBLU && this.renderBlockUser()}
            {this.props.operation === RECOVERY_OPERATION.PWDCAN && this.renderRemoveRequest()}
            {this.props.operation === RECOVERY_OPERATION.PWDSUB && this.renderChangePassword()}
          </>
        ) : (
          this.renderInvalidLinkInfo()
        )}
      </AuthPageOperation.Container>
    );
  }
}

export function RecoveryPage() {
  const navigate = useNavigate();
  const { id, uid, operation } = useQueryStrings();

  const hasAllData = Boolean(id && uid && operation);

  return hasAllData ? (
    <RecoveryPageComponent
      id={id}
      uid={uid}
      operation={operation}
      onNavigate={navigate}
    />
  ) : (
    <Link
      to={PATH.LOGIN}
      replace
    />
  );
}
