import React from "react";

import * as auth from "../../utils/authentication/authentication";
import LoginModal from "./login_modal";
import { AuthPageOperation } from "../../components/AuthPageOperation/AuthPageOperation";
import { CookiesBanner } from "./CookiesBanner";

class Login extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            cookiesAllowed: auth.isCookiesAllowed()
        }
    }

    allowCookies = () => {
        this.setState({
            cookiesAllowed: true
        }, function() {
            auth.allowCookies();
        })
    }

    renderCookieAllowPanel = () => {
        if (!this.state.cookiesAllowed){
            return <CookiesBanner onClick={this.allowCookies} />;
        }
    }

    render = () => {
        return (
            <div className="overflow-x-hidden">
                {navigator.cookieEnabled && this.renderCookieAllowPanel()}

                <AuthPageOperation.Background>
                    <AuthPageOperation.Header />

                    <div className='flex items-center justify-center flex-1'>
                        <LoginModal handleAuthentication={this.props?.handleAuthentication} />
                    </div>
                </AuthPageOperation.Background>
            </div>
        );
    }
}

export default Login;