import { Popup } from 'devextreme-react';

import { isUserOnMobile } from '../../utils/device/breakpoints';
import { Icon } from '../Icon/Icon';
import SimpleButtonComponent from '../button/simple_button';

export function ConfirmActionDialog({
  alertMessage,
  isVisible = true,
  message,
  children,
  cancelLabel,
  onCancel,
  confirmLabel,
  onConfirm,
  showCloseButton = false,
  showCancelButton = true,
  hideOnOutsideClick = null,
  title,
  height = 'auto',
  xsHeight = '100%',
  width = 440,
  xsWidth = '100%',
  id = undefined,
}) {
  const isMobile = isUserOnMobile();

  const props = {
    dragEnabled: false,
    height: isMobile ? xsHeight : height,
    hideOnOutsideClick: hideOnOutsideClick !== null ? hideOnOutsideClick : !showCloseButton,
    onHiding: onCancel,
    position: isMobile ? 'bottom' : null,
    shading: true,
    shadingColor: isMobile ? 'rgba(0,0,0,0)' : 'rgba(0,0,0,0.3)',
    showTitle: title?.length > 0 || showCloseButton,
    visible: isVisible,
    width: isMobile ? xsWidth : width,
  };

  const customTitle = () => (
    <div className='flex justify-between items-center gap-x-2 py-1'>
      <h3 className='text-lg text-brand-green500 font-semibold truncate'>{title}</h3>

      {showCloseButton && (
        <SimpleButtonComponent
          variant='icon-button'
          className='text-brand-gray400'
          onClick={props.onHiding}
        >
          <Icon
            name='x'
            size={18}
          />
        </SimpleButtonComponent>
      )}
    </div>
  );

  return (
    <Popup
      titleRender={customTitle}
      {...props}
    >
      <div
        id={id}
        className='flex h-full justify-between flex-col md:gap-8 pb-5 md:pb-0'
      >
        <div className='flex flex-col gap-10 md:gap-6'>
          <span>{message ?? children}</span>

          {alertMessage && <div className='font-semibold'>{alertMessage}</div>}
        </div>

        <div className='flex flex-col md:flex-row justify-end gap-4'>
          <SimpleButtonComponent
            variant='primary'
            className='w-full md:w-fit'
            onClick={onConfirm}
          >
            <span>{confirmLabel}</span>
          </SimpleButtonComponent>

          {showCancelButton && (
            <SimpleButtonComponent
              variant='primary'
              className='w-full md:w-fit'
              onClick={onCancel}
            >
              <span>{cancelLabel}</span>
            </SimpleButtonComponent>
          )}
        </div>
      </div>
    </Popup>
  );
}
