import { LOCAL_STORAGE } from '../storage/constants';
import { localStorageHelper } from '../storage/local_storage';

export function getMessage(messageId, params, translates, language) {
  const key = messageId.toUpperCase();

  if (!language || !translates) {
    return `key_${key}_lang_${language}_NOTFOUND`;
  }

  let translateFounded = translates.find((x) => x.key === key && x.language === language);

  if (translateFounded === undefined) {
    return `key_${key}_lang_${language}_NOTFOUND`;
  } else {
    if (params && params.length > 0) {
      params.map((value, index) => {
        index++;
        translateFounded.description = translateFounded.description.replaceAll(
          `%${index}`,
          value
        );
      });
    }

    return translateFounded.description;
  }
}

export function replaceWithData(text, data) {
  let message = text;

  data.forEach((d, index) => {
    message = message.replaceAll('{' + index + '}', d);
  });

  return message;
}

export function translateWithData(messageId, data) {
  let message = translate(messageId);

  return replaceWithData(message, data);
}

export function translate(messageId, params) {
  if (!messageId) {
    return '';
  }

  let language = getUserLanguage();
  let translates = localStorageHelper.getUserTranslates();

  return getMessage(messageId, params, translates, language);
}

export function isTranslateValid(messageId) {
  const notFoundTranslate = new RegExp(/_NOTFOUND$/);
  return !notFoundTranslate.test(translate(messageId));
}

export function buildDynamicTranslations(messageId) {
  if(messageId.includes("ANOANT")){
    const year = new Date().getUTCFullYear() - 1;
    return translateWithData(messageId, [year]);
  }
  if(messageId.includes("ANOATUAL")){
    const year = new Date().getUTCFullYear();
    return translateWithData(messageId, [year]);
  }
  return translate(messageId);
}

export function getUserLanguage() {
  return localStorageHelper.getValue(LOCAL_STORAGE.USER_LANGUAGE) ?? 'pt-PT';
}
