import logo from '../../assets/img/coollink.svg';

export function LogoCoollink() {
  return (
    <img
      alt='Logo'
      src={logo}
      className='object-contain h-10 md:h-8'
    />
  );
}
