import React from 'react';

import { translate } from '../../utils/translates/translates_helper';
import { LogoCoollink } from '../image/LogoCoollink';
import { Icon } from './../Icon/Icon';
import './footer.css';

export function Footer({ menus = [] }) {
  const sitemapMenus = menus.filter((p) => p.codigo === 6);
  const footerMenus = menus.filter((p) => p.codigo === 7);
  const socialMenus = menus.filter((p) => p.codigo === 8);

  return (
    <footer
      className='cb-component section fp-section fp-table active h-screen max-h-[970px]'
      data-fp-styles='null'
    >
          <div className='fp-tableCell h-full'>
        <div className='area flex h-full flex-col justify-center'>
          <div className='topo mt-auto'>
            <nav className='menuR'>
              <ul className='flex'>
                {sitemapMenus
                  .filter((p) => !p.parentId)
                  .map((menu) => (
                    <li
                      key={menu.id}
                      className='nav-item flex-1'
                    >
                      <a
                        href={menu.path}
                        className='nav-link'
                      >
                        {menu.title}
                      </a>
                      <ul>
                        {sitemapMenus
                          .filter((p) => p.parentId === menu.id)
                          .map((menuChild) => (
                            <li key={menuChild.id}>
                              <a
                                target='_blank'
                                href={menuChild.path}
                                className='flex flex-col items-start gap-y-2'
                                rel='noreferrer'
                              >
                                <span>{menuChild.title}</span>
                              </a>
                            </li>
                          ))}
                      </ul>
                    </li>
                  ))}
              </ul>
            </nav>
          </div>

          <div className='meio'>
            <div className='meio-wrapper flex'>
              {footerMenus
                .filter((p) => !p.parentId)
                .map((menu) => (
                  <div
                    key={menu.id}
                    className='meio-sec'
                  >
                    <ul className='menuM'>
                      <li className='nav-item'>
                        <span className='nav-link'>{menu.title}</span>

                        <ul>
                          {footerMenus
                            .filter((p) => p.parentId === menu.id)
                            .map((menuChild) => (
                              <li key={menuChild.id}>
                                <a
                                  target='_blank'
                                  href={menuChild.path}
                                  className='flex flex-col items-start gap-y-2'
                                  rel='noreferrer'
                                >
                                  <span>{menuChild.title}</span>
                                </a>
                              </li>
                            ))}
                        </ul>
                      </li>
                    </ul>
                  </div>
                ))}

              <div className='meio-sec empty'></div>

              <div className='meio-sec social-media self-center'>
                <div className='social flex justify-start gap-x-7'>
                  {socialMenus
                    .filter((p) => !p.parentId)
                    .map((menu) => (
                      <a
                        key={menu.id}
                        target='_blank'
                        href={menu.path}
                        title={menu.title}
                        rel='noreferrer'
                      >
                        <Icon
                          name={menu.title.toLowerCase()}
                          alt={menu.title.toLowerCase()}
                          size={20}
                        />
                      </a>
                    ))}
                </div>
              </div>
            </div>
          </div>

          <div className='copy mt-auto'>
            <div>
              © 2023 Banco Carregosa.
              <nav className='menuP'>
                <ul>
                  <li className='nav-item'>
                    <a
                      target='_blank'
                      href='https://www.bancocarregosa.com/pt/seguranca/privacidade-e-cookies/'
                      className='nav-link'
                      rel='40 noreferrer'
                    >
                      {translate('PRIVACY_AND_COOKIES')}
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      target='_blank'
                      href='https://www.bancocarregosa.com/pt/reclamacoes/'
                      className='nav-link'
                      rel='72 noreferrer'
                    >
                      {translate('COMPLAINTS')}
                    </a>
                  </li>

                  <li className='nav-item'>
                    <a
                      href='https://www.bkms-system.com/bkwebanon/report/clientInfo?cin=YsW2ZP&amp;c=-1&amp;language=por'
                      target='_blank'
                      className='nav-link'
                      rel='noreferrer'
                    >
                      {translate('IRREGULARITIES_PARTICIPATION')}
                    </a>
                  </li>
                </ul>
              </nav>
              <div className='nota'>{translate('CARREGOSA_TRADEMAKERS')}</div>
              
              <div className='mt-3 flex flex-row items-center justify-center gap-1 mb-6'>
                <span>{translate('MADE_BY')}:</span>
                <LogoCoollink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
