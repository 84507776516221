import React, { Component } from 'react';

import './App.css';
import AuthDialog from './components/base/auth_dialog';
import * as auth from './utils/authentication/authentication';
import * as cookiesHelper from './utils/cookies/cookie';
import './utils/format/currency_format';

import { LoadUserDataset } from './components/user/user_dataset';
import { Router } from './routes/Router';
import { PATH, URL_RESOURCE } from './routes/constants';
import { COOKIE } from './utils/cookies/constants';
import { logoutUser } from './utils/integration/logout';
import { LOCAL_STORAGE } from './utils/storage/constants';
import { localStorageHelper } from './utils/storage/local_storage';
import RetryAuthDialog from './features/login/retry_auth_dialog';

export class App extends Component {
  _initialState = {
    appCoins: [],
    appTables: [],
    appTranslates: [],
    pageList: [],
    userAccounts: [],
    userInfo: [],
    userMarkets: [],
    isResetPassword: false,
  };

  constructor(props) {
    super(props);

    const isUserAuthenticated = !navigator.cookieEnabled ? false : auth.isAuthenticated();
    const cachedUserData = !navigator.cookieEnabled ? null : localStorageHelper.getJsonValue(LOCAL_STORAGE.USER_DATA);

    let userData = this._initialState;

    if (isUserAuthenticated && cachedUserData) {
      userData = cachedUserData;
    }

    this.state = { user: userData };

    this.onAuthenticate = this.onAuthenticate.bind(this);
    this.onLogout = this.onLogout.bind(this);
  }

  handleErrorUserData(errorMessage) {
    cookiesHelper.setCookie(COOKIE.RESPONSE_DATASET, errorMessage, 5);
    logoutUser(PATH.LOGIN_ERROR);
  }

  onLogout() {
    this.setState((state) => ({ ...state, user: this._initialState }));

    logoutUser(PATH.LOGIN_LOGGEDOUT);
  }

  onAuthenticate(isUserAuthenticated, authData) {
    if (isUserAuthenticated && this.state.user.pageList.length === 0) {
      let data = this._initialState;

      LoadUserDataset()
        .then((result) => {
          const userInfo = result.userDataset.infoResult[0] ?? [];

          data = {
            appCoins: result.userDataset.coinsResult,
            appTables: result.userDataset.tableResult,
            appTranslates: result.userDataset.translateResult,
            pageList: result.userDataset.pageResult,
            userAccounts: result.userDataset.accountResult,
            userInfo: {
              ...userInfo,
              isPrivateBanker: userInfo?.UtilizadorMarca === 'Carregosa',
            },
            userMarkets: result.userDataset.marketResult,
          };
        })
        .catch((err) => {
          this.handleErrorUserData(err);
        })
        .finally(() => {
          const isResetPassword = Number(localStorageHelper.getAccessType()) === 0 && (Boolean(authData.isFirstLogin) || Boolean(authData.isPasswordExpired));
          const userData = { ...data, isResetPassword };

          this.setState((state) => ({ ...state, user: userData}));

          localStorageHelper.setJsonValue(LOCAL_STORAGE.USER_DATA, userData);
        });
    }
  }

  render() {
    return (
      <React.Fragment>
        <AuthDialog />
        <RetryAuthDialog />

        <Router
          onAuthenticate={this.onAuthenticate}
          onLogout={this.onLogout}
          user={this.state.user}
        />
      </React.Fragment>
    );
  }
}
