import { getLabel } from '../../utils/translates/login_labels';
import { Icon } from '../Icon/Icon';
import { Logo } from '../image/Logo';
import { JsxFromString } from '../JsxFromString/JsxFromString';
import { SecurityInformation } from '../SecurityInformation/SecurityInformation';
import SimpleButtonComponent from '../button/simple_button';
import LoadingComponent from '../loading/load_panel';
import { LanguageSelect } from '../LanguageSelect/LanguageSelect';

function AuthPageOperationSuccess({ buttonOnClick, buttonLabel, message }) {
  return (
    <div className='flex flex-col gap-6 py-4'>
      <div className='inline-flex items-center'>
        <Icon
          name='check'
          sizeMobile={28}
          size={32}
          className='text-brand-green300 pr-4'
        />

        <span className='text-brand-green500 font-semibold text-justify whitespace-pre-line'>
          <JsxFromString domString={message ?? getLabel('GENERIC_SUCCESS')} />
        </span>
      </div>

      {buttonOnClick && (
        <SimpleButtonComponent
          className='w-full'
          onClick={buttonOnClick}
        >
          <span>{buttonLabel ?? getLabel('ENTER')}</span>
        </SimpleButtonComponent>
      )}
    </div>
  );
}

function AuthPageOperationContainer({ isLoading, header, isLanguageSelectorVisible, children }) {
  return (
    <AuthPageBackground>
      <AuthPageHeader isLanguageSelectorVisible={isLanguageSelectorVisible} />

      <div className='flex items-center justify-center flex-1'>
        <div className='lg:max-w-screen-lg flex flex-col justify-between gap-4 overflow-auto bg-white p-6 shadow'>
          <h2 className='font-titulos text-brand-green500'>{header}</h2>

          {children}
        </div>
      </div>

      <LoadingComponent isVisible={isLoading} />
    </AuthPageBackground>
  );
}

function AuthPageBackground({ children }) {
  return (
    <div className='min-h-screen min-w-full lg:h-screen flex flex-col lg:w-screen bg-login bg-cover bg-no-repeat py-6 px-4 lg:px-8 gap-6'>
      {children}
    </div>
  );
}

function AuthPageHeader({ isLanguageSelectorVisible = true }) {
  return (
    <div className='flex flex-row gap-4 justify-between'>
      <Logo />
      {isLanguageSelectorVisible && <LanguageSelect />}
    </div>
  );
}

function AuthPageOperationContentTitle({ children }) {
  return <h3 className='text-base font-semibold'>{children}</h3>;
}

function AuthPageOperationContentDescription({ children }) {
  return <p className='text-justify whitespace-pre-line'>{children}</p>;
}

function AuthPageOperationContent({
  children,
  isSuccessVisible = false,
  successProps,
  hasSecurityInformation = false,
}) {
  let nodes = [children];

  if (children.length) {
    nodes = children;
  }

  return (
    <div className='flex flex-col gap-10 lg:gap-6 lg:flex-row'>
      <div className='flex-1 flex flex-col gap-4'>
        {isSuccessVisible ? <AuthPageOperationSuccess {...successProps} /> : nodes[0]}
      </div>

      {(hasSecurityInformation || nodes[1]) && (
        <>
          <div className='hidden border-l border-brand-gray300 lg:inline-flex' />

          <div className='flex flex-1 flex-col gap-3'>
            {nodes[1]}

            {hasSecurityInformation && <SecurityInformation />}
          </div>
        </>
      )}
    </div>
  );
}

export const AuthPageOperation = {
  Background: AuthPageBackground,
  Header: AuthPageHeader,
  Container: AuthPageOperationContainer,
  Content: AuthPageOperationContent,
  ContentTitle: AuthPageOperationContentTitle,
  ContentDescription: AuthPageOperationContentDescription,
};
