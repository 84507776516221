import clsx from 'clsx';

import { Icon } from '../Icon/Icon';
import { InvalidMessage } from './InvalidMessage';
import { InputLabel } from './InputLabel';

function RadioButton({
    checked,
    label,
    onChange,
    value,
    radio = false,
    variant = 'default',
    iconName = null,
    orientation = null,
    ...props
}) {
    return (
        <div className='flex flex-row items-center gap-1'>
            {iconName && (
                <Icon
                    name={iconName}
                    sizeMobile={22}
                    size={36}
                />
            )}

            <label
                className={clsx('hb-radiobtn flex cursor-pointer items-center', {
                    'border-b-2 pb-1 text-black': variant === 'default',
                    'border-b-brand-option-unselected': !checked && variant === 'default',
                    'border-b-brand-option-selected font-bold': checked && variant === 'default',
                    'flex-row-reverse justify-end gap-x-3 px-1 text-brand-green500': variant === 'radio',
                    'h-full min-w-[5rem] flex-1 justify-center border-2 border-brand-gray100 bg-brand-gray100 p-2 text-brand-green500':
                        variant === 'button',
                    'border-brand-green500 font-semibold': checked && variant === 'button',
                })}
            >
                <div
                    className={clsx({
                        'flex items-center gap-x-2': variant === 'radio',
                        'flex-row-reverse': orientation === 'reverse',
                    })}
                >
                    <input
                        type='radio'
                        customtype='radio'
                        className={clsx('border-none', {
                            'appearance-none': variant !== 'radio',
                            'accent-brand-option-selected selection:bg-brand-option-selected radio-button':
                                variant === 'radio',
                        })}
                        checked={checked}
                        onChange={onChange}
                        value={value}
                        {...props}
                    />

                    <span>{label}</span>
                </div>
            </label>
        </div>
    );
}

function MultiCheckButton({
    checked,
    label,
    onChange,
    value,
    radio = false,
    variant = 'default',
    iconName = null,
    orientation = null,
    ...props
}) {
    return (
        <div className='flex flex-row items-center gap-1'>
            {iconName && (
                <Icon
                    name={iconName}
                    sizeMobile={22}
                    size={36}
                />
            )}

            <label
                className={clsx('hb-radiobtn flex cursor-pointer items-center', {
                    'border-b-2 pb-1 text-black': variant === 'default',
                    'border-b-brand-option-unselected': !checked && variant === 'default',
                    'border-b-brand-option-selected font-bold': checked && variant === 'default',
                    'flex-row-reverse justify-end gap-x-3 px-1 text-brand-green500': variant === 'radio',
                    'h-full min-w-[5rem] flex-1 justify-center border-2 border-brand-gray100 bg-brand-gray100 p-2 text-brand-green500':
                        variant === 'button',
                    'border-brand-green500 font-semibold': checked && variant === 'button',
                })}
            >
                <div
                    className={clsx({
                        'flex items-center gap-x-2': variant === 'radio',
                        'flex-row-reverse': orientation === 'reverse',
                    })}
                >
                    <input
                        type='checkbox'
                        customtype='checkbox'
                        className={clsx('border-none', {
                            'appearance-none': variant !== 'radio',
                            'accent-brand-option-selected selection:bg-brand-option-selected radio-button':
                                variant === 'radio',
                        })}
                        checked={checked}
                        onChange={onChange}
                        value={value}
                        {...props}
                    />

                    <span>{label}</span>
                </div>
            </label>
        </div>
    );
}

export function RadioGroup({
    className,
    id,
    invalidMessage = null,
    isInvalid = false,
    label,
    hideLabelMobile = false,
    xsLayout = null,
    layout = 'horizontal',
    name = null,
    onChange = null,
    customClass,
    options,
    orientation = null,
    selectedOption = null,
    variant = 'default',
    multiSelect = false
}) {
    return (
        <fieldset
            className={clsx('hb-radiogroup group flex gap-2', className, {
                invalid: isInvalid,
                'flex-col': !className?.includes('flex-'),
            })}
        >
            <InputLabel
                inputId={id}
                className={clsx(
                    'flex-1 group-[.invalid]:font-semibold group-[.invalid]:text-invalid',
                    { hidden: !label },
                    { 'hidden lg:inline': label && hideLabelMobile }
                )}
            >
                {label}
            </InputLabel>

            <div
                className={clsx('flex flex-1 gap-4 lg:gap-3', customClass, {
                    'flex-col': layout === 'vertical' || xsLayout === 'vertical',
                    'lg:flex-col': layout === 'vertical' && xsLayout,
                    'flex-row': layout === 'horizontal' || xsLayout === 'horizontal',
                    'lg:flex-row': layout === 'horizontal' && xsLayout,
                })}
            >
                {options.map((option) => (
                    multiSelect ?
                        (
                            <MultiCheckButton
                                {...option}
                                key={option.value} 
                                checked={(String(selectedOption).split(',')).includes(option.value)}
                                name={name}
                                variant={variant}
                                orientation={orientation}
                                onChange={onChange}
                            />
                        )
                        :
                        (
                            <RadioButton
                                {...option}
                                key={option.value}
                                checked={String(option.value) === String(selectedOption)}
                                name={name}
                                variant={variant}
                                orientation={orientation}
                                onChange={onChange}
                            />
                        )
                ))}

                <InvalidMessage>{invalidMessage}</InvalidMessage>
            </div>
        </fieldset>
    );
}
