import resourcesConfig from "../../config/api_resources.json";
import { PATH } from "../../routes/constants";
import { localStorageHelper } from "../../utils/storage/local_storage";
import { downloadFile } from "../file/download";
import { getRequestUrl } from "../format/api";
import { LOCAL_STORAGE } from "../storage/constants";
import { logoutUser } from "./logout";

const RESPONSE_STATUS = {
    NOT_FOUND: 404,
    SERVER_ERROR: 500,
    SUCCESS_297: 297,
    SUCCESS_298: 298,
    SUCCESS_299: 299,
    UNAUTHORIZED: 401,
}

export const ERRORS = {
    DUPLICATED_CALL: "Chamada duplicada"
}

function base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
        bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
}

function arrayBufferToBase64(buffer) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

export async function importPublicKey(spki) {
    const binaryDer = base64ToArrayBuffer(spki);
    var cryptoKey = await window.crypto.subtle
        .importKey(
            "spki",
            binaryDer,
            {
                name: 'RSA-OAEP',
                modulusLength: 256,
                hash: { name: 'sha-256' }
            },
            false,
            ["encrypt"]
        );
    return cryptoKey;
}

export async function encryptData(message, cryptoKey) {
    let enc = new TextEncoder();
    let encodedMessage = enc.encode(message);
    var encryptedData = await window.crypto.subtle.encrypt(
        {
            name: "RSA-OAEP"
        },
        cryptoKey,
        encodedMessage
    );
    var encodedData = arrayBufferToBase64(encryptedData);
    return encodedData;
}


export function postLogin(payload) {
    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resourcesConfig.AUTHENTICATION.LOGIN);
        const urlKey = getRequestUrl(resourcesConfig.AUTHENTICATION.PUBLIC_KEY);
        fetch(urlKey)
            .then(response => response.text())
            .then((response) => {
                importPublicKey(response).then(key => {
                    encryptData(payload.password, key).then(encryptedpw => {
                        payload.password = encryptedpw;
                        payload.publicKey = response;
                        fetch(url, {
                            method: "POST",
                            credentials: 'include',
                            headers: { 'Content-Type': 'application/json' },
                            body: JSON.stringify(payload)
                        }).then(response => {
                            if (response.ok) {
                                localStorageHelper.startInactivityDate();
                                resolve(response.json());
                            } else {
                                response.json().then((errorMessage) => reject(errorMessage));
                            }
                        }).catch(err => {
                            reject("Something is wrong!");
                        });
                    }).catch(err => {
                        reject("Something is wrong!");
                    });
                }).catch(err => {
                    reject("Something is wrong!");
                });
            }).catch(err => {
                reject("Something is wrong!");
            });
    });
}

export function postLoginTransaction(resource, payload, isRetry = false, successFunction, cancelFunction) {
    localStorageHelper.refreshInactivityDate();

    return new Promise(function (resolve, reject) {
        if(!checkLastCall(resource, payload))
            return reject(ERRORS.DUPLICATED_CALL);

        const url = getRequestUrl(resource);

        fetch(url, {
            method: "POST",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                logoutUser(PATH.LOGIN_UNAUTHORIZED);
            }
            if (response.status === RESPONSE_STATUS.NOT_FOUND || response.status === RESPONSE_STATUS.SERVER_ERROR) {
                logoutUser(PATH.LOGIN_ERROR);
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_299) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.secondFactor(Message);
                        if (successFunction)
                            window.authDialog.successFunction = successFunction;
                        if (cancelFunction)
                            window.authDialog.cancelFunction = cancelFunction;
                    }
                });
            } else if (response.status === RESPONSE_STATUS.SUCCESS_297) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ShowConfirmErrorMessage();
                    }
                });
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_298) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.closeDialog();
                        if (window.authDialog.successFunction)
                            window.authDialog.successFunction(window.authDialog.TransactionID);
                    }
                });
            }
            else if (response.ok) {
                resolve(response.json());
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject(err);
        });
    });
}

export function postWithoutAuth(resource, payload) {
    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resource);

        fetch(url, {
            method: "POST",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.ok) {
                resolve(response.json());
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject("Something is wrong!");
        });
    });
}

export function getWithoutAuth(resource, params) {
    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resource);

        var fullUrl = new URL(url);
        fullUrl.search = new URLSearchParams(params);

        fetch(fullUrl, {
            method: "GET",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.ok) {
                resolve(response.json());
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject("Something is wrong!");
        });
    });
}

export function downloadDocument(resource, imageId, fileName, isRetry = false) {
    localStorageHelper.refreshInactivityDate();

    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resource);

        var fullUrl = new URL(url);
        fullUrl.search = new URLSearchParams({
            imageId: imageId,
            downloadFileName: fileName
        });
        fetch(fullUrl, {
            method: "GET",
            credentials: 'include'
        })
            .then(response => response.blob())
            .then(blob => downloadFile(blob, fileName))
            .catch(err => {
                reject(err);
            });
    });
}

export function getImage(resource, imageId, fileName, isRetry = false) {
    localStorageHelper.refreshInactivityDate();

    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resource);

        var fullUrl = new URL(url);
        fullUrl.search = new URLSearchParams({
            imageId: imageId,
            downloadFileName: fileName
        });
        fetch(fullUrl, {
            method: "GET",
            credentials: 'include'
        }).then(response => {
            response.blob().then(blob => {
                const imageURL = window.URL.createObjectURL(blob);
                resolve(imageURL);
            })
        }).catch(err => {
            reject(err);
        });
    });
}

export function post(resource, payload, isRetry = false, successFunction, cancelFunction, isPriority = false) {
    return new Promise(function (resolve) {
        manageQueueCalls(executeCall, isPriority);
        function executeCall(){
            resolve(fetchPost(resource, payload, isRetry, successFunction, cancelFunction, isPriority));
        }
    })
}

export function get(resource, params, isRetry = false, successFunction, cancelFunction, isPriority = false) {
    return new Promise(function (resolve) {
        manageQueueCalls(executeCall, isPriority);
        function executeCall(){
            resolve(fetchGet(resource, params, isRetry, successFunction, cancelFunction, isPriority));
        }
    })
}

function manageQueueCalls(callbackProcessCall, isPriority = false){
    var semaphoreOn = localStorageHelper.isAPIProcessing();
    if(semaphoreOn && !isPriority){
        console.log("Waiting...")
        setTimeout(function(){
            return manageQueueCalls(callbackProcessCall)
        }, 2000);
    } else {
        return callbackProcessCall();
    }
}

function fetchPost(resource, payload, isRetry = false, successFunction, cancelFunction, isPriority = false) {
    localStorageHelper.refreshInactivityDate();

    return new Promise(function (resolve, reject) {
        if(!checkLastCall(resource, payload))
            return reject(ERRORS.DUPLICATED_CALL);

        const url = getRequestUrl(resource);

        fetch(url, {
            method: "POST",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        }).then(response => {
            if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                if(localStorageHelper.getValue(LOCAL_STORAGE.IS_CMD) === "true" || isPriority){
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.closeDialog();
                    }
                    logoutUser(PATH.LOGIN_UNAUTHORIZED);
                } else {
                    handleRetryAuthentication().then(success => {
                        if(success){
                            resolve(post(resource, payload, isRetry, successFunction, cancelFunction))
                        } else {
                            logoutUser(PATH.LOGIN_UNAUTHORIZED);
                        }
                    }).catch(error => {
                        logoutUser(PATH.LOGIN_UNAUTHORIZED);
                    });
                }                
            } else if (response.status === RESPONSE_STATUS.NOT_FOUND || response.status === RESPONSE_STATUS.SERVER_ERROR) {
                logoutUser(PATH.LOGIN_ERROR);
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_299) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.secondFactor(Message);
                        if (successFunction)
                            window.authDialog.successFunction = successFunction;
                        if (cancelFunction)
                            window.authDialog.cancelFunction = cancelFunction;
                    }
                });
            } else if (response.status === RESPONSE_STATUS.SUCCESS_297) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ShowConfirmErrorMessage();
                    }
                });
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_298) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.closeDialog();
                        if (window.authDialog.successFunction)
                            window.authDialog.successFunction(window.authDialog.TransactionID);
                    }

                });
            }
            else if (response.ok) {
                resolve(response.json());
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject(err);
        });
    });
}

function fetchGet(resource, params, isRetry = false, successFunction, cancelFunction, isPriority = false) {
    localStorageHelper.refreshInactivityDate();

    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resource);

        var fullUrl = new URL(url);
        fullUrl.search = new URLSearchParams(params);

        fetch(fullUrl, {
            method: "GET",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                if(localStorageHelper.getValue(LOCAL_STORAGE.IS_CMD) === "true" || isPriority){
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.closeDialog();
                    }
                    logoutUser(PATH.LOGIN_UNAUTHORIZED);
                } else {
                    handleRetryAuthentication().then(success => {
                        if(success){
                            resolve(get(resource, params, isRetry, successFunction, cancelFunction))
                        } else {
                            logoutUser(PATH.LOGIN_UNAUTHORIZED);
                        }
                    }).catch(error => {
                        logoutUser(PATH.LOGIN_UNAUTHORIZED);
                    });
                }                
            }
            else if (response.status === RESPONSE_STATUS.NOT_FOUND || response.status === RESPONSE_STATUS.SERVER_ERROR) {
                logoutUser(PATH.LOGIN_ERROR);
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_299) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.secondFactor(Message);
                        if (successFunction)
                            window.authDialog.successFunction = successFunction;
                        if (cancelFunction)
                            window.authDialog.cancelFunction = cancelFunction;

                    }
                });
            } else if (response.status === RESPONSE_STATUS.SUCCESS_297) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ShowConfirmErrorMessage();
                    }
                });
            }
            else if (response.status === RESPONSE_STATUS.SUCCESS_298) {
                response.text().then((Message) => {
                    if (window.authDialog !== undefined) {
                        window.authDialog.ClearConfirmErrorMessage();
                        window.authDialog.closeDialog();
                        if (window.authDialog.successFunction)
                            window.authDialog.successFunction(window.authDialog.TransactionID);
                    }

                });
            }
            else if (response.ok) {
                resolve(response.json());
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject(err);
        });
    });
}

export function checkSessionHealth() {
    return new Promise(function (resolve, reject) {
        const url = getRequestUrl(resourcesConfig.AUTHENTICATION.CHECK_SESSION);
        var fullUrl = new URL(url);

        fetch(fullUrl, {
            method: "GET",
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' }
        }).then(response => {
            if (response.status === RESPONSE_STATUS.UNAUTHORIZED) {
                logoutUser(PATH.LOGIN_UNAUTHORIZED);
            }
            else if (response.status === RESPONSE_STATUS.NOT_FOUND || response.status === RESPONSE_STATUS.SERVER_ERROR) {
                logoutUser(PATH.LOGIN_ERROR);
            }
            else if (response.ok) {
                var semaphoreOn = localStorageHelper.isAPIProcessing();
                if(semaphoreOn)
                    logoutUser(PATH.LOGIN_UNAUTHORIZED);
                resolve(true);
            } else {
                response.text().then((errorMessage) => reject(errorMessage));
            }
        }).catch(err => {
            reject(err);
        });
    });
}

function checkLastCall(resource, params){
    let currentCallData = localStorageHelper.hashCode(`${resource}${JSON.stringify(params)}`);
    let lastCall = localStorageHelper.getLastCall();
    
    if(lastCall){
        let lastCallDate = lastCall.split('.')[0];
        if(new Date(lastCallDate) >= new Date()){
            if(currentCallData.toString() === lastCall.split('.')[1])
                return false;              
        }
    }
    
    var timeToRefresh = new Date();
    timeToRefresh.setSeconds(new Date().getSeconds() + 1);
    let currentCall = `${timeToRefresh}.${currentCallData}`;
    localStorageHelper.setLastCall(currentCall);
    return true;
}

function handleRetryAuthentication(){
    return new Promise(function (resolve, reject) {
        localStorageHelper.setAPIProcessing(1);
        if (window.retryAuthDialog !== undefined) {
            window.retryAuthDialog.handleAuthenticationDialog(callbackRetry);
        } else {
            return reject();
        }
        
        function callbackRetry(isSuccess){
            localStorageHelper.setAPIProcessing(0);

            if(isSuccess)
                return resolve(isSuccess);
            else
                return reject(isSuccess);
        }
    });
}

export const caller = {
    downloadDocument,
    get,
    getImage,
    post,
    postLogin,
    postWithoutAuth,
    getWithoutAuth,
    checkSessionHealth,
    ERRORS
};