import clsx from 'clsx';

import { getLabel } from '../../utils/translates/login_labels';
import { translate } from '../../utils/translates/translates_helper';
import { Icon } from '../Icon/Icon';
import SimpleButtonComponent from './simple_button';

export function BackButton({ className, hasIcon = true, label,  onClick, ...props }) {
  return (
    <SimpleButtonComponent
      {...props}      
      onClick={onClick}
      variant='link-text'
    >
      {hasIcon && <Icon
        alt={translate('BACK')}
        className='rotate-90'
        name='seta'
        size={16}
      />}

      <span className={clsx(className, 'font-bold')}>{label ?? getLabel('BACK')}</span>
    </SimpleButtonComponent>
  );
}
