import React, { Fragment } from 'react';

import { getFunctionFromString } from '../text';

function getElementAttributes({ isReact, node }) {
  let attributes = {};

  if (node.attributes) {
    Array.from(node.attributes).forEach((attribute) => {
      let attributeValue = attribute.nodeValue;

      if (attribute.name === 'style') {
        let styleAttributes = attribute.nodeValue.split(';');
        let styleObj = {};

        styleAttributes.forEach((attribute) => {
          let [key, value] = attribute.split(':');
          styleObj[key] = value;
        });

        attributeValue = styleObj;
      }

      if (isReact && attribute.name.startsWith('on')) {
        attributeValue = getFunctionFromString(`return ${attributeValue}`);
      }

      const attributeName = attribute.name.replace(/^class$|^classname$/, 'className');

      attributes[attributeName] = attributeValue;
    });
  }

  return attributes;
}

export function createJsx({ isReact, nodes }) {
  return Array.from(nodes).map((node, index) => {
    const attributes = getElementAttributes({ isReact, node });
    const hasChildren = node.childNodes && Array.isArray(Array.from(node.childNodes));

    return (
      <Fragment key={index}>
        {node.localName
          ? React.createElement(
              node.localName,
              attributes,
              hasChildren ? createJsx({ isReact, nodes: node.childNodes }) : []
            )
          : node.nodeValue}
      </Fragment>
    );
  });
}
