import React from "react";

import { getLabel, getLink } from "../../utils/translates/login_labels";
import SimpleButtonComponent from "../../components/button/simple_button";
import { LinkButton } from "../../components/button/LinkButton";

export function CookiesBanner({ onClick }) {
    return (
        <>
            <div className="w-screen h-screen bg-brand-gray700 opacity-60 absolute lg:hidden" />
            <div className="bg-white absolute bottom-0 w-full flex flex-col lg:flex-row justify-between items-center py-6 lg:py-5 px-4 lg:px-8 gap-6">
                <p>
                    <span>{getLabel("COOKIE_INFO")}&nbsp;</span>
                    <LinkButton
                        isExternal
                        href={getLink('KNOW_MORE_SECURITY')}
                    >
                        <span>{getLabel("PRIVACY_POLICY")}</span>
                    </LinkButton>.
                </p>

                <SimpleButtonComponent variant="primary" className="w-full lg:w-fit" onClick={onClick}>
                    {getLabel("CLOSE")}
                </SimpleButtonComponent>
            </div>
        </>
    );
}