import { getLabel, getLabelWithData, getLink } from '../../utils/translates/login_labels';
import { JsxFromString } from '../JsxFromString/JsxFromString';

export function SecurityInformation() {
  return (
    <div className='flex flex-col gap-2 text-left text-brand-gray500'>
      <p className='font-semibold'>{getLabel('SECURITY_WARNING_TITLE')}</p>

      <JsxFromString domString={getLabel('SECURITY_WARNING_TEXT')} />

      <p className='mt-3'>
        <JsxFromString
          domString={getLabelWithData('SECURITY_WARNING_LINK', [
            getLink('KNOW_MORE_SECURITY'),
            '_blank',
          ])}
        />
      </p>
    </div>
  );
}
