import { localStorageHelper } from "../storage/local_storage";

export function getLabel(label, language = null) {
    if (!label) {
        return "";
    }

    const key = label.toUpperCase();
    language = language ?? getUserLanguage();
    let translates = AuthDialogLabels;

    if (language === undefined || translates === undefined) {
        return `key_${key}_lang_${language}_NOTFOUND`;
    }

    let translateFounded = translates.find((x) => x.Mnemonica === key && x[language]);
    if (translateFounded === undefined) {
        return `key_${key}_lang_${language}_NOTFOUND`;
    } else {
        return translateFounded[language];
    }
}

function getUserLanguage() {
    let language = localStorageHelper.getUserLanguage();
    if (language === undefined || language === null) {
        return "PT";
    } else {
        if (language.split("-").length === 2) 
            language = language.split("-")[1];
  
        return language;
    }
}

const AuthDialogLabels = [
    {	
        Mnemonica: "SEG_FACT_REQ",
        PT: "Para prosseguir com a operação insira o Código de Validação que enviamos para o seu telemóvel nº ",
        US: "In order to proceed with the operation please enter the Validation Code we sent to your mobile nr. ",
        ES: "Para prosseguir com a operação insira o Código de Validação que enviamos para o seu telemóvel nº ",
    },	
    {	
        Mnemonica: "SEG_FACT_MSG_PART1",
        PT: "Caso não tenha recebido o SMS no prazo de um minuto pode gerar um novo Código de Validação",
        US: "In case you have not received the SMS within one minute, you can request a new Validation Code",
        ES: "Caso não tenha recebido o SMS no prazo de um minuto pode gerar um novo Código de Validação",
    },	
    {	
        Mnemonica: "SEG_FACT_MSG_PART2",
        PT: "aqui",
        US: "here",
        ES: "aqui",
    },	
    {	
        Mnemonica: "SEG_FACT_RESEND_SUCCESS",
        PT: "Um novo Código de Validação foi gerado com sucesso!",
        US: "A new Validation Code has been successfully generated!",
        ES: "¡Se ha generado exitosamente un nuevo Código de Validación!",
    },	
    {	
        Mnemonica: "AVISOS",
        PT: "Avisos",
        US: "Warning",
        ES: "Avisos",
    },	
    {	
        Mnemonica: "AVISOS_PART1",
        PT: "Confirme sempre os dados no SMS que enviamos.",
        US: "Always confirm the data on the SMS that was sent.",
        ES: "Confirme sempre os dados no SMS que enviamos.",
    },	
    {	
        Mnemonica: "AVISOS_PART2",
        PT: "O envio da mensagem de SMS é da responsabilidade dos operadores de telecomunicações, pelo o que o Banco não pode ser responsabilizado caso o SMS nao seja rececionado em tempo útil.",
        US: "The network operator is responsible for the SMS service and the Bank cannot be held responsible if the SMS is not received in a timely manner.",
        ES: "O envio da mensagem de SMS é da responsabilidade dos operadores de telecomunicações, pelo o que o Banco não pode ser responsabilizado caso o SMS nao seja rececionado em tempo útil.",
    },	
    {	
        Mnemonica: "AVISOS_PART3",
        PT: "Nº de telemóvel registado nos seus dados pessoais.",
        US: "Mobile phone registered in your personal data.",
        ES: "Nº de telemóvel registado nos seus dados pessoais.",
    },	
    {	
        Mnemonica: "VALIDATION",
        PT: "Validação",
        US: "Validation",
        ES: "Validação",
    },	
    {	
        Mnemonica: "WRONG_CODE",
        PT: "O código digitado está incorreto!",
        US: "The code entered is incorrect!",
        ES: "O código digitado está incorreto!",
    },	
    {	
        Mnemonica: "CONFIRM",
        PT: "confirmar",
        US: "confirm",
        ES: "confirmar",
    },
    {
      Mnemonica: "CANCEL_CONFIRMATION_2",
      PT: "Ao sair, a operação não será confirmada e poderá perder dados não guardados, deseja continuar?",
      US: "If you log out, the operation will not be confirmed and you may lose unsaved data. Do you want to continue?",
      ES: "Ao sair, a operação não será confirmada e poderá perder dados não salvos, deseja continuar?",
    },
    {
      Mnemonica: "LOGOUT",
      PT: "Sair",
      US: "Logout",
      ES: "Salir",
    },
    {
      Mnemonica: "BACK",
      PT: "Voltar",
      US: "Back",
      ES: "Voltar",
    }
];	