import { createContext, useContext, useState } from 'react';

import { getLoginLanguage, setLoginLanguage } from '../utils/translates/login_labels';

export const LANGUAGES = [
  { RecordID: '1', Descricao: 'PT', Value: 'pt-PT' },
  { RecordID: '2', Descricao: 'EN', Value: 'en-US' },
  // { RecordID: '4', Descricao: 'ES', Value: 'es-ES' },
  //{ RecordID: '5', Descricao: 'FR', Value: 'fr-FR' },
];

const LanguageContext = createContext({ languageId: null, onChange: () => null });

export function LanguageContextProvider({ children }) {
  const cachedLanguage = getLoginLanguage();
  const [languageId, setLanguageId] = useState(cachedLanguage?.id ?? '1');

  const onChange = (id, value) => {
    setLanguageId(id);
    setLoginLanguage(id, value);
  };

  return (
    <LanguageContext.Provider value={{ languageId, onChange }} key={languageId}>
      {children}
    </LanguageContext.Provider>
  );
}

export const useLanguageContext = () => useContext(LanguageContext);
