import { LANGUAGES, useLanguageContext } from '../../contexts/LanguageContext';
import SelectBoxComponent from '../list/selectbox';
import './LanguageSelect.css';

export function LanguageSelect() {
  const { languageId, onChange } = useLanguageContext();

  const handleChangeLanguage = (languageId) => {
    const language = LANGUAGES.find((lang) => lang?.RecordID === languageId);

    onChange(language.RecordID, language.Value);
  };

  return (
    <SelectBoxComponent
      height='auto'
      width='5rem'
      id='login-language-select'
      manualItems={LANGUAGES}
      callback={handleChangeLanguage}
      selectedValue={languageId}
      className='border-none'
    />
  );
}
