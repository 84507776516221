import { useEffect } from 'react';
import { createPortal } from 'react-dom';

import { PageTitle } from '../title/page_title';

const CONTAINER_ID = 'printable-container';
const NON_PRINTABLE_CSS_CLASS = 'noPrint';

const getNonPrintableElements = () => {
  const bodyChildren = Array.from(document.body.children);

  return bodyChildren.filter((element) => element.id !== CONTAINER_ID);
};

const setNonPrintableElements = (elements, isPrintable = false) => {
  if (isPrintable) {
    elements.forEach((element) => element.classList.remove(NON_PRINTABLE_CSS_CLASS));
  } else {
    elements.forEach((element) => element.classList.add(NON_PRINTABLE_CSS_CLASS));
  }
};

export function PrintContainer({ title, content, callback }) {
  useEffect(() => {
    const invisibleElements = getNonPrintableElements();

    const handleBeforePrint = () => {
      setNonPrintableElements(invisibleElements, false);

      setTimeout(callback, 0);
    };

    const handleAfterPrint = () => {
      setNonPrintableElements(invisibleElements, true);
    };

    window.addEventListener('beforeprint', handleBeforePrint);
    window.addEventListener('afterprint', handleAfterPrint);

    window.print();

    return () => {
      window.removeEventListener('beforeprint', handleBeforePrint);
      window.removeEventListener('afterprint', handleAfterPrint);
    };
  }, [callback]);

  if (!title && !content) {
    return null;
  }

  return createPortal(
    <div
      id={CONTAINER_ID}
      className='m-4 hidden print:block'
    >
      {title && (
        <PageTitle.Container>
          <PageTitle.Heading>{title}</PageTitle.Heading>
        </PageTitle.Container>
      )}

      <div
        id='printable-content'
        className='mt-4'
      >
        {content}
      </div>
    </div>,
    document.body
  );
}
