import { localStorageHelper } from "../../utils/storage/local_storage";
import { LOCAL_STORAGE } from "../storage/constants";

export function createCaptcha(divId) {
    if(document.getElementById(divId) === null)
        return;
        
    let divDimensions = document.getElementById(divId);
    document.getElementById(divId).innerHTML = "";
    var charsArray = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var lengthOtp = 6;
    var captcha = [];

    for (var i = 0; i < lengthOtp; i++) {
        var index = Math.floor(Math.random() * charsArray.length + 1);
        if (captcha.indexOf(charsArray[index]) == -1)
            captcha.push(charsArray[index]);
        else 
            i--;
    }

    var canv = document.createElement("canvas");
    canv.id = divId;
    canv.width = divDimensions.offsetWidth;
    canv.height = divDimensions.offsetHeight;
    var ctx = canv.getContext("2d");
    ctx.font = "25px Georgia";
    ctx.textAlign = "center";
    ctx.textBaseline = 'middle';
    ctx.strokeText(captcha.join(""), canv.width/2, canv.height/2);

    localStorageHelper.setValue(LOCAL_STORAGE.CAPTCHA, captcha.join(""));
    document.getElementById(divId).appendChild(canv);
}

export function validateCaptcha(captcha) {
    if (captcha === localStorageHelper.getValue(LOCAL_STORAGE.CAPTCHA)) {
        return true;
    }else{
        return false;
    }
}
