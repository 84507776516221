export const LOCAL_STORAGE = {
  ACCOUNT_IBAN: 'accountIBAN',
  ACCOUNT_ID: 'accountId',
  COMPANY_ACCOUNT: 'companyAccount',
  ACCOUNT: 'account',
  CAPTCHA: 'captcha',
  HAS_MARGIN_ACCOUNT: 'hasMarginAccount',
  INACTIVITY_DATE: 'inactivityDate',
  PREVIOUS_CHECK: 'previousCheck',
  TEST_MODE: 'testMode',
  USER_DATA: 'hb_user_data',
  USER_EXPIRATION_TIME: 'userExpirationTime',
  USER_LANGUAGE_ID: 'userLanguageId',
  USER_LANGUAGE_LOGIN: 'hb_user_language_login',
  USER_LANGUAGE: 'userLanguage',
  VALIDATION: 'validation',
  USER_VERIFY_FORMS_VISIBLE: 'user_verify_forms_visible',
  CMD_LOGIN: 'cmdId',
  EXPIRED_USER_MESSAGE_VIEW: 'expired_user_message_view',
  LAST_CALL: 'lastCall',
  LOGIN_TYPE: 'loginType',
  LOGIN_USERNAME: 'loginUserName',
  API_PROCESSING: 'isAPIAuthProcessing',
  IS_CMD: 'isChaveMovelDigital'
};
