import { SelectBox } from "devextreme-react/select-box";
import DataSource from "devextreme/data/data_source";
import Query from 'devextreme/data/query';
import "devextreme/dist/css/dx.light.css";
import React from "react";
import { loadDictionary } from "../../server/dictionary";
import { translate } from "../../utils/translates/translates_helper";
import "./selectbox.css";

class SelectBoxComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dictionaryList: [],
            isLoaded: false,
            dataSource: null
        };
    }

    static defaultProps = {
        handleFirstValueSelected: true
    }

    loadDictionaryComplete = () => {
        if (this.props.loadDictionaryComplete)
            this.props.loadDictionaryComplete(this);
    };


    getDictionary = () => {
        loadDictionary(this.props.dictionaryKey)
            .then((response) => {


                const hasManualItems = this.props.manualItems?.length > 0;
                let dataset = hasManualItems ? [...this.props.manualItems, ...response.dictionaryList] : response.dictionaryList;

                var ds = dataset.filter(
                    (v, i, a) => a.findIndex((v2) => v2.RecordID === v.RecordID) === i
                );

                this.dataSetReady(ds, response.dictionaryList);

            })
            .catch(() => {
                this.setState({
                    isLoaded: true,
                });
            });
    };

    concatenateAPIDictionaryWithManualItems = () => {
        if (this.props.dictionaryKey === null || this.props.dictionaryKey === undefined) {
            if (this.props.manualItems !== null || this.props.manualItems !== undefined) {
                return this.dataSetReady(this.props.manualItems);

            } else {
                return this.dataSetReady(null);

            }
        }

        if (this.state.dictionaryList.length === 0 && this.state.isLoaded === false) {
            this.getDictionary();
            return this.dataSetReady(null);
        }
        else if (this.state.dataSource != null)
            return this.state.dataSource;
        else return this.dataSetReady(null);

    };

    emptyDataset = () => {
        return [
            { RecordID: 1, Descricao: '' }
        ]
    }

    dataSetReady = (dataset, dictionaryList) => {
        if (dataset === null || dataset === undefined || dataset.length === 0) {
            return this.emptyDataset;
        }

        this.handleFirstValueSelected(dataset);

        let formattedDataset = new DataSource({
            store: {
                type: "array",
                data: dataset,
                key: "RecordID"
            },
            group: this.props.isGroupped ? "Category" : null
        });

        if (this.props.isGroupped) {
            formattedDataset.postProcess = function (data) {
                let sorted = Query(data).sortBy(item => {
                    return item.items[0].CategoryId;
                }).toArray();

                if (typeof dictionaryList !== "undefined")
                    this.setState({
                        dataSource: sorted,
                        dictionaryList: dictionaryList,
                        isLoaded: true,
                    }, this.loadDictionaryComplete);
                else
                    return sorted;
            }
        }

        if (typeof dictionaryList !== "undefined") {
            this.setState({
                dataSource: formattedDataset,
                dictionaryList: dictionaryList,
                isLoaded: true,
            }, this.loadDictionaryComplete);
        }
        else
            return formattedDataset;
    }
    buildListDataset = () => {
        return this.concatenateAPIDictionaryWithManualItems();
    };

    handleChangedValue = (e) => {
        if (this.props.callback) {
            this.props.callback(e.value);
        }

        if (this.props.valueChangedCallback) {
            this.props.valueChangedCallback(e);
        }
    }

    updateValue = (e) => {
        this.handleChangedValue(e);
    };

    renderItem = (data) => {
        return data.Descricao;
    };

    renderDisplay = (item) => {
        if (this.props.isGroupped && !this.props.hideGroupLabel)
            return item && item.Category + " - " + item.Descricao;
        else
            return item && item.Descricao;
    };

    handleFirstValueSelected = (dataset) => {
        if ((this.props.handleFirstValueSelected) && dataset && dataset[0] && dataset[0].RecordID)
            if (this.props.selectedValue === undefined || this.props.selectedValue === null && dataset.length !== 0)
                this.handleChangedValue(dataset[0].RecordID)

    }

    initialized = (e) => {
        if (this.props.focus) {
            setTimeout(() => {
                e.component.focus();
            });
        }
    }

    renderListBox = () => {
        let dataSte = this.buildListDataset();
        return (
            <SelectBox
                dataSource={dataSte}
                height={this.props.height ?? 40}
                width={this.props.width ?? null}
                searchEnabled={this.props.searchEnabled ?? null}
                searchExpr={this.props.searchExpr ?? null}
                valueExpr={(this.props.valueExpr) ?? "RecordID"}
                grouped={this.props.isGroupped ?? false}
                displayExpr={this.renderDisplay}
                value={this.props.selectedValue}
                placeholder={this.props.placeholder ?? translate("SELECT")}
                noDataText={this.props.noDataText ?? translate("NO_DATA_TEXT")}
                onValueChanged={this.updateValue}
                itemRender={this.renderItem}
                id={this.props.id ?? ""}
                name={this.props.name ?? ""}
                indicateLoading={true}
                wrapItemText={true}
                disabled={this.props.disabled ?? false}
                showClearButton={this.props.showClearButton ?? false}
                inputAttr={this.props.inputAttr ?? null}
                onInitialized={this.initialized}
                focusStateEnabled={this.props.focusStateEnabled ?? true}
                className={(this.props.className) ?? null}
            />
        );
    };

    render() {
        return this.renderListBox();
    }
}

export default SelectBoxComponent;
