import clsx from 'clsx';
import React, { useRef } from 'react';

import './Button.css';

function SimpleButtonComponent({ variant = 'primary', className = '', ...props }) {
  const buttonRef = useRef(false);

  const handleClick = (event) => {
    if(buttonRef.current === false){
      if (props.onClick) {
        props.onClick(props.value, event);
      }
      buttonRef.current = true;
    }
    setTimeout(() => {
      buttonRef.current = false;
    }, 1000);
  };

  return (
    <button
      {...props}
      onClick={handleClick}
      className={clsx('custom-button', variant, className)}
    />
  );
}

export default SimpleButtonComponent;
