import clsx from 'clsx';
import zxcvbn from 'zxcvbn';

import { getLabel } from '../../utils/translates/login_labels';

const STRENGTH = {
  VERY_WEAK: 0,
  WEAK: 1,
  MEDIUM: 2,
  GOOD: 3,
  EXCELENT: 4,
};

const STRENGTH_LABEL = {
  [STRENGTH.VERY_WEAK]: 'PASSWORD_VERY_WEAK',
  [STRENGTH.WEAK]: 'PASSWORD_WEAK',
  [STRENGTH.MEDIUM]: 'PASSWORD_MEDIUM',
  [STRENGTH.GOOD]: 'PASSWORD_GOOD',
  [STRENGTH.EXCELENT]: 'PASSWORD_EXCELENT',
};

export function PasswordStrength({ password = '' }) {
  const { score = 0 } = zxcvbn(password);

  return (
    <>
      <div className='h-1.5 w-full rounded-full bg-neutral-200'>
        <div
          className={clsx('h-1.5 rounded-full', {
            'w-1/4 bg-[#c81818]': score === STRENGTH.WEAK,
            'w-2/4 bg-[#e28f00]': score === STRENGTH.MEDIUM,
            'w-3/4 bg-[#8aa050]': score === STRENGTH.GOOD,
            'w-4/4 bg-[#27b30f]': score === STRENGTH.EXCELENT,
          })}
        />
      </div>

      <span
        className={clsx('text-xs', {
          'text-[#c81818]': score === STRENGTH.WEAK,
          'text-[#e28f00]': score === STRENGTH.MEDIUM,
          'text-[#8aa050]': score === STRENGTH.GOOD,
          'text-[#27b30f]': score === STRENGTH.EXCELENT,
        })}
      >
        {getLabel(STRENGTH_LABEL[score])}
      </span>
    </>
  );
}
