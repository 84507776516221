const BASE_PATH = window.GLOBAL_APP_BASE_PATHL
const HOMEBANKING_PATH = window.GLOBAL_APP_OLD_HB;
const SERVER_PATH = window.GLOBAL_APP_SERVER_URL;
const HOMEBANKING_INSTITUCIONAL = window.GLOBAL_SITE_INSTUTICIONAL;

export const URL_RESOURCE = {
  ALL: '*',
  ACTIVITY_HISTORY_PAGE: 'activity_history?tId=',
  SUBSCRIPTION_ORDERS: 'management_event_order',
  MANAGE_ORDERS: 'management_order',
  MANAGE_SUBSCRIPTION_ORDERS: 'management_event_order',
  BALANCE_PAGE: 'balance',
  FINANCIAL_SECURITIES_PAGE: 'financial_securities',
  RESCUE_DEPOSITS_PAGE: 'rescue_deposits',
  INSERT_ORDER_PAGE: 'insert_order',
  HOME_PAGE: 'dashboard',
  LOGIN_CMD: 'loginchavemoveldigital',
  LOGIN_ERROR: 'login?u=2',
  LOGIN_MODAL: 'loginmodal',
  LOGIN_UNAUTHORIZED: 'login?u=1',
  LOGIN_LOGGEDOUT: 'login?u=3',
  LOGOUT_DMIF: '&lt=1',
  LOGIN_AS_USER: 'loginasuser',
  LOGIN: 'login',
  CHANGE_PASSWORD: 'changepassword',
  RECOVERY: 'recovery',
  LOGOUT: 'logout',
  PROFITABILITY: 'profitability',
  FREQUENT_DEST: 'frequent_recipients',
  FREQUENT_DEST_TAB: 'frequent_recipients?tab=',
  INSTRUMENT_RESEARCH: 'instrument_research?pId=',
  PATRIMONY_PAGE: 'patrimony',
  PRODUCT_SUMMARY: 'instrument_research',
  UPDATE_SUBSCRIPTION_ORDER: 'order_subscription',
  UPDATE_MANAGEMENT_ORDER: 'insert_order',
  COMPARE_MORNINGSTAR_TOOL: 'compare_tool?securityIds=',
  REPORT_MORNINGSTAR_TOOL: 'report_tool?id=',
  INVESTMENT_FUNDS_DECO: 'deco_portfolio',
  SEARCH_MORNINGSTAR: 'search'
};

export const PATH = {
  BASE: BASE_PATH,
  SERVER: SERVER_PATH,
  INSTITUCIONAL: HOMEBANKING_INSTITUCIONAL,
  CHAT: HOMEBANKING_PATH + 'aminhaconta/Pages/Ferramentas/WebChat/WebChat.aspx',
  OLD_HOMEBANKING: HOMEBANKING_PATH,
  LOGIN_CMD: BASE_PATH + '/' + URL_RESOURCE.LOGIN_CMD,
  LOGIN_ERROR: BASE_PATH + '/' + URL_RESOURCE.LOGIN_ERROR,
  LOGIN_MODAL: BASE_PATH + '/' + URL_RESOURCE.LOGIN_MODAL,
  LOGIN_UNAUTHORIZED: BASE_PATH + '/' + URL_RESOURCE.LOGIN_UNAUTHORIZED,
  LOGIN_LOGGEDOUT: BASE_PATH + '/' + URL_RESOURCE.LOGIN_LOGGEDOUT,
  LOGIN: BASE_PATH + '/' + URL_RESOURCE.LOGIN,
  CHANGE_PASSWORD: BASE_PATH + '/' + URL_RESOURCE.CHANGE_PASSWORD,
  LOGOUT: BASE_PATH + '/' + URL_RESOURCE.LOGOUT,
  PAGE_ACTIVITY_HISTORY: BASE_PATH + '/' + URL_RESOURCE.ACTIVITY_HISTORY_PAGE,
  PAGE_BALANCE: BASE_PATH + '/' + URL_RESOURCE.BALANCE_PAGE,
  PAGE_PATRIMONY: BASE_PATH + '/' + URL_RESOURCE.PATRIMONY_PAGE,
  PAGE_FINANCIAL_SECURITIES: BASE_PATH + '/' + URL_RESOURCE.FINANCIAL_SECURITIES_PAGE,
  PAGE_MAIN: BASE_PATH + '/' + URL_RESOURCE.HOME_PAGE,
  PAGE_RESCUE_DEPOSITS: BASE_PATH + '/' + URL_RESOURCE.RESCUE_DEPOSITS_PAGE,
  PAGE_INSERT_ORDER: BASE_PATH + '/' + URL_RESOURCE.INSERT_ORDER_PAGE,
  PAGE_MANAGE_ORDERS: BASE_PATH + '/' + URL_RESOURCE.MANAGE_ORDERS,
  PAGE_SUBSCRIPTION_ORDERS: BASE_PATH + '/' + URL_RESOURCE.MANAGE_SUBSCRIPTION_ORDERS,
  PAGE_INSTRUMENT_RESEARCH: BASE_PATH + '/' + URL_RESOURCE.INSTRUMENT_RESEARCH,
  PAGE_UPDATE_ORDER_SUBSCRIPTION: BASE_PATH + '/' + URL_RESOURCE.UPDATE_SUBSCRIPTION_ORDER,
  PAGE_UPDATE_MANAGEMENT_ORDER: BASE_PATH + '/' + URL_RESOURCE.UPDATE_MANAGEMENT_ORDER,
  PAGE_COMPARE_TOOL: BASE_PATH + '/' + URL_RESOURCE.COMPARE_MORNINGSTAR_TOOL,
  PAGE_REPORT_TOOL: BASE_PATH + '/' + URL_RESOURCE.REPORT_MORNINGSTAR_TOOL,
  PAGE_FREQUENT_DEST: BASE_PATH + '/' + URL_RESOURCE.FREQUENT_DEST,
  PAGE_FREQUENT_DEST_TAB: BASE_PATH + '/' + URL_RESOURCE.FREQUENT_DEST_TAB,
  PAGE_PROFITABILITY: BASE_PATH + '/' + URL_RESOURCE.PROFITABILITY,
  PAGE_DECO_PORTFOLIO: BASE_PATH + '/' + URL_RESOURCE.INVESTMENT_FUNDS_DECO,
  PAGE_PRODUCT_SUMMARY: BASE_PATH + '/' + URL_RESOURCE.PRODUCT_SUMMARY,
  PAGE_SEARCH_MORNINGSTAR: BASE_PATH + '/' + URL_RESOURCE.SEARCH_MORNINGSTAR
};

export default PATH;