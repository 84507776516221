import resourcesConfig from "../config/api_resources.json";
import { get } from "../utils/integration/integration";
import { translate } from "../utils/translates/translates_helper";

export async function loadDictionary(dictionaryKey) {
  let dictionaryList = [];
  let errorMessage = "";

  await get(resourcesConfig.DICTIONARY.LOAD_DICTIONARY, {
    DictionaryKey: dictionaryKey,
  })
    .then((response) => {
      dictionaryList = response.result.results[0];
    })
    .catch(() => {
      errorMessage = translate("GENERIC_ERROR");
    });

  return { dictionaryList, errorMessage };
}
